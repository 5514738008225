import React from 'react'
// import PropTypes from 'prop-types'

const CoronaInformationDashboard = props => {
    return (
        <div style={{height: "90vh"}}>
            {/* <iframe src="https://maps.mapmyindia.com/corona" height="100%" width="100%"></iframe>  */}
        </div>
    )
}


export default CoronaInformationDashboard
