import React, { useState, useCallback, useEffect } from "react";
import "./CaseStudy.css";
import { CaseStudyCardData } from "../Constants";
// import fallback from "../../Content/images/logo-lorri_backup.png";

const CaseStudyCard = React.memo(({ details }) => {
  const {
    title,
    companyName,
    personName,
    designation,
    thumbNailImage,
    embedVideoLink,
  } = details;
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <div  className="CaseStudyCard__Container">
      <div className="CaseStudyCard__VideoContainer">
        {isLoading && (
          <div className="CaseStudyCard__Fallback">
            <img width="100%" height="235" src={thumbNailImage} alt={title} />
            <div className="CaseStudyCard__LoadingOverlay">
              <div className="CaseStudyCard__LoadingSpinner"></div>
            </div>
          </div>
        )}
        <iframe
          width="100%"
          height="240"
          src={embedVideoLink}
          title={`${personName} - ${companyName}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          onLoad={handleIframeLoad}
          style={{ display: isLoading ? "none" : "block" }}
        />
      </div>
      <div className="card-info">
        <div>
          <h3 className="person-name">{personName}</h3>
          <p className="designation">{designation}</p>
        </div>
        <p className="company-name">{companyName}</p>
      </div>
    </div>
  );
});

const CaseStudySection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = useCallback(() => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + CaseStudyCardData.length) % CaseStudyCardData.length
    );
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % CaseStudyCardData.length);
  }, []);

  const visibleCards = CaseStudyCardData.slice(
    currentIndex,
    currentIndex + 3
  ).concat(
    CaseStudyCardData.slice(
      0,
      Math.max(0, 3 - (CaseStudyCardData.length - currentIndex))
    )
  );

  return (
    <div className="CaseStudy__container">
      <div className="CaseStudy__Container-header" data-aos="fade-up">
        <h4>LoRRI References</h4>
      </div>
      <div className="CaseStudy__Details">
        <div
          className="CaseStudy__Carousel"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {visibleCards.map((details) => (
            <div key={details.id} className="CaseStudy__Slide">
              <CaseStudyCard details={details} />
            </div>
          ))}
        </div>
        <div className="CaseStudy__Carousel-nav">
          <button onClick={handlePrev}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329z" />
            </svg>
          </button>
          <button onClick={handleNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m321-80-71-71 329-329-329-329 71-71 400 400z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaseStudySection;
