import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import india from "./india.json";
import "./Map.css";
import { Urls } from "../../../Urls";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

// Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set(
//   Urls.mapboxAccessToken
// );

const Map = ({ inflow = [], outflow = [], searched = [] }) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [showInbound, setShowInbound] = useState(true);
  const [showOutbound, setShowOutbound] = useState(true);

  const [showLegendPopup, setShowLegendPopup] = useState(false);
  const [marker, setMarker] = useState(null);

  const toggleLegendPopup = () => {
    setShowLegendPopup(!showLegendPopup);
  };

  useEffect(() => {
    const initializeMap = () => {
      const map = new mapboxgl.Map({
        accessToken: Urls.mapboxAccessToken,
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/light-v11",
        center: [78.7480, 23.8438],
        zoom: 3.2,
        maxZoom: 6,
        // projection: {
        //   name: "mercator",
        //   center: [78.7480, 23.8438],
        // },
      });

      map.on("load", () => {
        map.addSource("india-outline", {
          type: "geojson",
          data: india,
        });

        map.addLayer({
          id: "india-outline",
          type: "line",
          source: "india-outline",
          layout: {},
          paint: {
            "line-color": "#393185",
            "line-width": 1,
            "line-opacity": 0.4,
          },
        });

        setMap(map);
      });

      return () => map.remove();
    };

    if (!map) initializeMap();
  }, []);

  useEffect(() => {
    if (!map) return;
    removeMarkers();
    clearAllFlows();
    plotMarkers();

    if (inflow.length > 0 || searched.length > 0) {
      if (showInbound) {
        plotInflow();
      } else {
        removeInflow();
      }
    } else {
      removeInflow();
    }

    if (outflow.length > 0 || searched.length > 0) {
      if (showOutbound) {
        plotOutflow();
      } else {
        removeOutflow();
      }
    } else {
      removeOutflow();
    }
  }, [inflow, outflow, searched, map, showInbound, showOutbound]);

  const clearAllFlows = () => {
    removeInflow();
    removeOutflow();
  };

  const removeInflow = () => {
    if (map.getLayer("inflow")) {
      map.removeLayer("inflow");
    }
    if (map.getSource("inflow")) {
      map.removeSource("inflow");
    }
  };

  const removeOutflow = () => {
    if (map.getLayer("outflow")) {
      map.removeLayer("outflow");
    }
    if (map.getSource("outflow")) {
      map.removeSource("outflow");
    }
  };
  const plotMarkers = () => {
    if (searched.length > 0) {
      const currentMarker = new mapboxgl.Marker()
        .setLngLat([searched[0], searched[1]])
        .addTo(map);
      setMarker(currentMarker);
    }
  };

  const removeMarkers = () => {
    if (searched.length > 0 && marker) {
      marker.remove();
    }
  };

  const plotInflow = () => {
    if (inflow.length > 0 && searched.length > 0) {
      const data = {
        type: "FeatureCollection",
        features: inflow.map((item) => ({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [item.origin_coordinates, [searched[0], searched[1]]],
          },
        })),
      };

      if (!map.getSource("inflow")) {
        map.addSource("inflow", { type: "geojson", data });
      } else {
        map.getSource("inflow").setData(data);
      }

      if (!map.getLayer("inflow")) {
        map.addLayer({
          id: "inflow",
          type: "line",
          source: "inflow",

          paint: {
            "line-color": "#393185",
            "line-width": 1,
            "line-opacity": 0.8,
          },
        });
      }
    }
  };

  const plotOutflow = () => {
    if (outflow.length > 0 && searched.length > 0) {
      const data = {
        type: "FeatureCollection",
        features: outflow.map((item) => ({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [
              [searched[0], searched[1]],
              item.destination_coordinates,
            ],
          },
        })),
      };

      if (!map.getSource("outflow")) {
        map.addSource("outflow", { type: "geojson", data });
      } else {
        map.getSource("outflow").setData(data);
      }

      if (!map.getLayer("outflow")) {
        map.addLayer({
          id: "outflow",
          type: "line",
          source: "outflow",

          paint: {
            "line-color": "#54AF3A",
            "line-width": 1,
            "line-opacity": 0.8,
          },
        });
      }
    }
  };

  return (
    <>
      <div ref={mapContainerRef} className="map-container">
        <div className="legend-popup-icon" onClick={toggleLegendPopup}>
          &#9776;
        </div>
        {showLegendPopup && (
          <div className="legend-popup-content">
            <div className="legend-title">Locations</div>
            <hr />
            <div>
              <input
                type="checkbox"
                id="inbound"
                checked={showInbound}
                onChange={(e) => setShowInbound(e.target.checked)}
              />
              <label
                htmlFor="inbound"
                style={{ color: "#393185", cursor: "pointer" }}
              >
                Inbound Movement
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="outbound"
                checked={showOutbound}
                onChange={(e) => setShowOutbound(e.target.checked)}
              />
              <label
                htmlFor="outbound"
                style={{ color: "#54AF3A", cursor: "pointer" }}
              >
                Outbound Movement
              </label>
            </div>
          </div>
        )}

        <div className="legend-box">
          <div className="legend-title">Locations</div>
          <hr></hr>
          <div>
            <input
              type="checkbox"
              id="inbound"
              checked={showInbound}
              onChange={(e) => setShowInbound(e.target.checked)}
            />
            <label
              htmlFor="inbound"
              style={{ color: "#393185", cursor: "pointer" }}
            >
              Inbound Movement
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="outbound"
              checked={showOutbound}
              onChange={(e) => setShowOutbound(e.target.checked)}
            />
            <label
              htmlFor="outbound"
              style={{ color: "#54AF3A", cursor: "pointer" }}
            >
              Outbound Movement
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map;
