import React from 'react'
import RouterOutlet from '../../Routes'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { BrowserRouter } from 'react-router-dom'

const Applayout = props => {
    return (
        <BrowserRouter>
            <Header/>
            <RouterOutlet/>
            <Footer/>
        </BrowserRouter>
    )
}


export default Applayout
