import React, { useState } from "react";
import Location from "react-autosuggest";
import debounce from "lodash/debounce";
import Request from "axios-request-handler";
import { Urls } from "../../Urls";
// import theme from "react-autosuggest/dist/theme";
// import styled from "styled-components";
import "./AutoComplete.css";
import { withRouter } from "react-router-dom";
// const classNames = {
//   container: "react-autosuggest__container",
//   input: "react-autosuggest__input",
//   inputFocused: "react-autosuggest__input--focused",
//   inputOpen: "react-autosuggest__input--open",
//   suggestionsContainer: "react-autosuggest__suggestions-container",
//   suggestionsContainerOpen: "react-autosuggest__suggestions-container--open",
//   suggestionsList: "react-autosuggest__suggestions-list",
//   suggestion: "react-autosuggest__suggestion",
//   suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
// };
export const requestInstance = new Request({
  lockable: false, // if true if you try to make a request when there is a pending one, the second will not be executed
  cancelable: true, // if true if you try to make a request when there is a pending one, the first will be canceled and the new will executed
  errorHandler: (error, method) => {}, // function for handling the errors
});
const AutoComplete = ({
  onSelect,
  // className = "",
  placeholder,
  onChange,
  value,
  key,
  inputClassName = "",
  id = "Autosuggets_Input_id",
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [noSuggestions, setNoSuggestions] = useState(false);
  const [show, setShow] = useState(false);

  const onSuggestionsFetchRequested = debounce(({ value }) => {
    if (value.length >= 3) {
      // setShow(true)
      //   axios.get(`${Urls.Autocomplete}?suggest=${value}&limit=20&searchFields=locations`)
      //   .then(res=>{
      //     setShow(false)
      //     setSuggestions([])
      //       setSuggestions(res.data.value)
      //   })
      //   .catch(err=>{
      //     setShow(false)
      //   })
      // let url=`${Urls.Autocomplete}?suggest=${value}&limit=20&searchFields=locations`
      let url = `${Urls.Autocomplete}?suggest=${value}&limit=20&searchFields=new_locations`;
      requestInstance.setUrl(url); // changes the instance's url
      if (requestInstance.isPending("get")) {
        requestInstance.cancel("get");
      }
      setShow(true);
      requestInstance
        .get()
        .then((res) => {
          setSuggestions([]);
          setSuggestions(res.data.value);
          setShow(false);
          if(res.data.value.length < 1) {
            setNoSuggestions(true)
          } else {
            setNoSuggestions(false)
          }
        })
        .catch((err) => {
          // setShow(false)
          console.log(err)
        });
    }
  }, 500);
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const getSuggestionValue = (value) => {
    return value.location_name;
  };
  const renderSuggestion = (value) => {
    return value.location_name;
  };
  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    onChange(suggestion.location_name);
    onSelect(suggestion);
  };
  // const shouldRenderSuggestions = (value) => {
  //   return value.trim().length % 3 === 0;
  // };

  const inputProps = {
    value: value,
    onChange: (e) => onChange(e.target.value),
    placeholder: placeholder,
    key: key,
    id: id,
    className: inputClassName,
  };
  return (
    <div className="Loader-Container">
      <Location
        suggestions={suggestions}
        onSuggestionsFetchRequested={(value) =>
          onSuggestionsFetchRequested(value)
        }
        onSuggestionsClearRequested={(e) => onSuggestionsClearRequested()}
        getSuggestionValue={(value) => getSuggestionValue(value)}
        renderSuggestion={(value) => renderSuggestion(value)}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        // theme={theme}
      />
      <div className={`preloader-wrapper small ${show ? "active" : ""}`}>
        <div className="spinner-layer" style={{borderColor: "#3e3c3c"}}>
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
      {noSuggestions && <div className='no_suggestion'>No location found</div>}
    </div>
  );
};

export default withRouter(AutoComplete);
