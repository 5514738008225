import React, { useEffect } from "react";
import reportpage1 from "./covidreport/1.jpg";
import reportpage2 from "./covidreport/2.jpg";
import reportpage3 from "./covidreport/3.jpg";
import reportpage4 from "./covidreport/4.jpg";
import reportpage5 from "./covidreport/5.jpg";
import reportpage6 from "./covidreport/6.jpg";
import { Activity, currentRequestTimeStamp } from "./../Constants";

const Covid19ImpactReport = (props) => {
  useEffect(() => {
    Activity([
      {
        screen: "Corona report page",
        module: "Corona report page",
        user_activity: "Corona report page visited",
        timestamp: currentRequestTimeStamp(),
      },
    ]);
  }, []);

  const reportPages = [
    reportpage1,
    reportpage2,
    reportpage3,
    reportpage4,
    reportpage5,
    reportpage6,
  ];

  const imgStyle = {
    display: "block",
    margin: "0 auto 20px",
    maxWidth: "100%",
    height: "auto",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow style
    transition: "transform 0.3s ease", // Animation transition
  };

  return (
    <div>
      {reportPages.map((page, index) => (
        <div key={index}>
          <img src={page} style={imgStyle} alt={`Report page ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default Covid19ImpactReport;
