import React, { useEffect, useState } from "react";
import { Urls } from "../../Urls";
import { Activity, currentRequestTimeStamp } from "./../Constants";
import ReactGA from "react-ga";
import "./IntroSection.css";
import mixpanel from "mixpanel-browser";

const tileData = [
  {
    id: "benchmark-link",
    image: "icons8-combo-chart-100.png",
    title: "Get benchmark for your lanes",
    link: `${Urls.New_BaseUrls}/benchmarknow`,
    onClick: () => {
      Activity([
        {
          screen: "Header",
          module: "What is LoRRI",
          user_activity: "Benchmark Tile Clicked",
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Header",
        action: `Benchmark Tile Clicked`,
      });

      mixpanel.track(
        `User has clicked on "Get benchmark for your lanes" tile`,
        {
          ApplicationName: `${Urls.mixpanelappname}`,
          ScreenName: "home",
          ModuleName: "landing_page",
          ActivityLog: `userClickedGetBenchmarkTile`,
          Data: `None`,
          Definition: `User has clicked on "Get benchmark for your lanes" tile`,
          ActivityType: "event_view",
        }
      );
    },
  },
  {
    id: "indent-link",
    image: "icons8-truck-64.png",
    title: "Create and manage Indents and track vehicles",
    link: `${Urls.New_BaseUrls}/indents`,
    onClick: () => {
      Activity([
        {
          screen: "Header",
          module: "What is LoRRI",
          user_activity: "Indent Tile Clicked",
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Header",
        action: `Indent Tile Clicked`,
      });
      mixpanel.track(
        `User has clicked on "Create and manage Indents and track vehicles" tile`,
        {
          ApplicationName: `${Urls.mixpanelappname}`,
          ScreenName: "home",
          ModuleName: "landing_page",
          ActivityLog: `userClickedIndentTile`,
          Data: `None`,
          Definition: `User has clicked on "Create and manage Indents and track vehicles" tile`,
          ActivityType: "event_view",
        }
      );
    },
  },
  {
    id: "auction-link",
    image: "icons8-auction-100.png",
    title: "Run contracted and spot procurements with end-to-end auction",
    link: `${Urls.New_BaseUrls}/auction`,
    onClick: () => {
      Activity([
        {
          screen: "Header",
          module: "What is LoRRI",
          user_activity: "Auction Tile Clicked",
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Header",
        action: `Auction Tile Clicked`,
      });
      mixpanel.track(
        `User has clicked on "Run contracted and spot procurements with end-to-end auction" tile`,
        {
          ApplicationName: `${Urls.mixpanelappname}`,
          ScreenName: "home",
          ModuleName: "landing_page",
          ActivityLog: `userClickedAuctionTile`,
          Data: `None`,
          Definition: `User has clicked on "Run contracted and spot procurements with end-to-end auction" tile`,
          ActivityType: "event_view",
        }
      );
    },
  },
  {
    id: "location-link",
    image: "icons8-change-user-80.png",
    title: "Find best fit transporters for your plants",
    link: `${Urls.New_BaseUrls}/locationdashboard`,
    onClick: () => {
      Activity([
        {
          screen: "Header",
          module: "What is LoRRI",
          user_activity: "Location Dashboard Tile Clicked",
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Header",
        action: `Location Dashboard Tile Clicked`,
      });
      mixpanel.track(
        `User has clicked on "Find best fit transporters for your plants" tile`,
        {
          ApplicationName: `${Urls.mixpanelappname}`,
          ScreenName: "home",
          ModuleName: "landing_page",
          ActivityLog: `userClickedTransporterTile`,
          Data: `None`,
          Definition: `User has clicked on "Find best fit transporters for your plants" tile`,
          ActivityType: "event_view",
        }
      );
    },
  },
  {
    id: "synergy-link",
    image: "icons8-road-80.png",
    title: "Run synergy for your lanes",
    link: `${Urls.New_BaseUrls}/synergynow`,
    onClick: () => {
      Activity([
        {
          screen: "Header",
          module: "What is LoRRI",
          user_activity: "SynergyNow Tile Clicked",
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Header",
        action: `SynergyNow Tile Clicked`,
      });
      mixpanel.track(`User has clicked on "Run synergy for your lanes" tile`, {
        ApplicationName: `${Urls.mixpanelappname}`,
        ScreenName: "home",
        ModuleName: "landing_page",
        ActivityLog: `userClickedSynergyNowTile`,
        Data: `None`,
        Definition: `User has clicked on "Run synergy for your lanes" tile`,
        ActivityType: "event_view",
      });
    },
  },
  {
    id: "analytics-link",
    image: "icons8-financial-analytics-96.png",
    title: "Generate analytics to gauge transporter performance",
    link: `${Urls.New_BaseUrls}/synergynow`,
    onClick: () => {
      Activity([
        {
          screen: "Header",
          module: "What is LoRRI",
          user_activity: "SynergyNow Tile Clicked",
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Header",
        action: `SynergyNow Tile Clicked`,
      });
      mixpanel.track(`User has clicked on "Run synergy for your lanes" tile`, {
        ApplicationName: `${Urls.mixpanelappname}`,
        ScreenName: "home",
        ModuleName: "landing_page",
        ActivityLog: `userClickedSynergyNowTile`,
        Data: `None`,
        Definition: `User has clicked on "Run synergy for your lanes" tile`,
        ActivityType: "event_view",
      });
    },
  },
];

const IntroSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getGridSize = (screenWidth) => {
    if (screenWidth > 1200) {
      return 3;
    } else if (screenWidth > 550) {
      return 2;
    } else {
      return 1;
    }
  };

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "16px",
          color: "#393185",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        Powering Freight Intelligence, Procurement and End to End
        Digitization/TMS at Leading Companies in India.
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "18px",
          paddingBottom: "16px",
          color: "#393185",   
        }}
      >
        An end-to-end logistics platform to take control of your logistical
        challenges and create friction-less trucking and supply chain
        operations.
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${getGridSize(screenWidth)}, 1fr)`,
          gridGap: "32px",
          margin: "48px 5%",
          background:
            "linear-gradient(to right, rgba(255, 255, 255, 0), #f5f5f5, rgba(255, 255, 255, 0))",
        }}
      >
        {tileData.map((tile) => (
          <a
            key={tile.id}
            className="custom-intro-tile"
            href={tile.link}
            target="_blank"
            id={tile.id}
            rel="noopener noreferrer"
            onClick={tile.onClick}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-8px)";
              e.currentTarget.style.boxShadow =
                "0 8px 16px rgba(0, 0, 0, 0.15)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
            }}
          >
            <img
              src={require(`../../Content/images/${tile.image}`)}
              alt={tile.title}
              style={{
                width: "96px",
                height: "96px",
                marginBottom: "16px",
              }}
            />
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "8px",
              }}
            >
              {tile.title}
            </span>
            <span
              style={{
                fontSize: "14px",
                textAlign: "center",
                color: "#757575",
              }}
            >
              {tile.description}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default IntroSection;
