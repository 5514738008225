import React, { useEffect, useState } from "react";
import classes from "./Companies.module.css";
import { allLogos } from "../Constants";

const MobileLogos = () => {
  const [scaledLogoIndex, setScaledLogoIndex] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleScale = (index) => {
    if (scaledLogoIndex === index) {
      setScaledLogoIndex(null);
    } else {
      setScaledLogoIndex(index);
    }
  };

  return (
    <div
      className={classes["mobile-logos-container"]}
      style={{
        // background: "linear-gradient(to bottom, #f0f0f0, #e0e0e0)",
        padding: "20px",
      }}
    >
      <center
        className={classes["heading"]}
        style={{
          marginBottom: "25px",
          fontWeight: "bold",
        }}
      >
        LoRRI makes a difference
      </center>
      <div
        className={classes["mobile-logos-grid"]}
        style={{
          justifyContent: "space-evenly",
          alignItems: "center",
          display: "flex",
          flexWrap: windowWidth < 380 ? "nowrap" : "wrap",
          overflowX: "scroll",
        }}
      >
        {allLogos.map((logo, index) => (
          <div
            key={index}
            className={classes["mobile-logo-item"]}
            style={{
            //   paddingRight: "7px",
            //   paddingLeft: "7px",
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   verticalAlign: "middle",
            //   height: "120px",
            //   width: "120px",
            //   minWidth: "100px",
              transform: scaledLogoIndex === index ? "scale(1.2)" : "scale(1)",
              transition: "transform 0.1s ease-in-out",
            }}
            onClick={() => toggleScale(index)}
          >
            <img
              src={logo.src}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                transform: "scale(1.2)",
              }}
              title={logo.title}
              alt={logo.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileLogos;
