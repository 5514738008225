import transport_alt from "../Content/images/transporter_logo.png";
// import axios from "axios";
// import logURL from './../Urls'
// import Urls from './../Urls'
// import { Urls } from "./../Urls";
import jyothy from "../Content/images/Jyothy_Labs_Log0.png";
import cj from "../Content/images/cjdarcl.png";
import abr from "../Content/images/abr.png";
import shahways from "../Content/images/shahways.png";
import perfetti from "../Content/images/perfetti.png";
import hb from "../Content/images/hector-beverages-removebg-cropped.png";
import shell from "../Content/images/shell_new.png";
import bosch from "../Content/images/bosch.png";
import Onida from "../Content/images/2560px-Onida_Electronics.svg.png";
import PernardLogo from "../Content/images/Pernod_Ricard_logo_2019.svg";
import Schreiber from "../Content/images/schreider_dynamix-logo-removebg-preview.png";
import Skechers from "../Content/images/Skechers-Logo.png";
import SaintGobain from "../Content/images/1280px-Saint-Gobain_logo.svg.png";
import Varuna from "../Content/images/varuna-removebg-preview.png";
import ShreeManohar from "../Content/images/smt-new.png";
import Ekart from "../Content/images/Ekart-logo.png";
import ExpressRoadways from "../Content/images/express-roadways.png";

const FileUrl = process.env.REACT_APP_API_LogoBaseUrl;

export const isValidPhoneNumber = (value) =>
  value && !isNaN(parseInt(value)) && parseInt(value).toString().length === 10;

export const isValidEmail = (value) =>
  value &&
  // new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value);
  new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(value);
export const token = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vYjMxYTQzODYtNjczMi00MjFlLWI2YzEtODIzNmUxOGJhMzhjL3YyLjAvIiwiZXhwIjoxNTgyNjI5MjA2LCJuYmYiOjE1ODI1NDI4MDYsImF1ZCI6IjczZDUzYmZjLTRiOWMtNDQ0OC05M2UxLTcxYzBjMGJmYTQ0ZiIsIm9pZCI6IjU1MGY2NjM2LWFlNmYtNDNmMi1hZjE4LWNiYzFkNzhkYWZmOSIsInN1YiI6IjU1MGY2NjM2LWFlNmYtNDNmMi1hZjE4LWNiYzFkNzhkYWZmOSIsImV4dGVuc2lvbl9Db21wYW55TmFtZSI6IkxOIiwibmFtZSI6IlNuZWhhIiwiZXh0ZW5zaW9uX0FncmVlZFRvVGVybXNBbmRDb25kaXRpb25zIjoidHJ1ZSIsImVtYWlscyI6WyJzbmVoYUBsb2dpc3RpY3Nub3cuaW4iXSwidGZwIjoiQjJDXzFfU2lnbl9VcF9TaWduX0luIiwibm9uY2UiOiJiY2YyM2QyZC04OTBmLTRkNjctOGUwYi05OWM5NzdhODYyMTciLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJhenAiOiI3M2Q1M2JmYy00YjljLTQ0NDgtOTNlMS03MWMwYzBiZmE0NGYiLCJ2ZXIiOiIxLjAiLCJpYXQiOjE1ODI1NDI4MDZ9.srTtUrF9eAtfpwWI7CexIx80yB0elerHbWSDVFxsV9F4QI0WcNDMTtVwNNhPmTPDti1sRUFM03vvqzWasIeEIVPydMTCGzTaFgt-O28N-9DGmB2mETgRe2AekgAcSSmKg0TA9VnKbBbRrVRKw35SSw9ZcwiOBfHOnrBmSkeCcFD3ZHxpYtQSNB4Us2Z5NUvkZOmqkdL0nSxB-fGgxQaQTJbQVvEUrT9kuUYY7MazG6-lzLIYEvEfMX4cse1OtrkYWj-M8aRYPSBl1CXbQRUcRTAkyaH_iCBLZ7TPZjWrD49AN2cyJEVlnzyWSZpGVsj9vRXn-gDin3fNOII0nt_iqg`;
export const getImageSrc = (logo) => {
  if (logo && logo.length > 1) return `${FileUrl}/${logo}`;
  return transport_alt;
};
export const imageFallBack = (e) => {
  e.target.src = transport_alt;
};
export const encryptor = (transporterID = "", shouldEncode) => {
  let characters = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm".split(
    ""
  );
  const generateRandomCharcter = () =>
    characters[Math.floor(Math.random() * characters.length)];
  if (shouldEncode) {
    let k = transporterID.split("");
    let encodedID = "";
    k.map((item, index) => {
      encodedID += item;
      for (let i = 0; i <= index; i++) {
        encodedID += generateRandomCharcter();
      }
    });
    return encodedID;
  } else {
    let decodedID = "";
    let startIndex = 0,
      nextIndex = 2;
    while (transporterID.length > startIndex) {
      decodedID += transporterID[startIndex];
      startIndex += nextIndex;
      nextIndex++;
    }
    return decodedID;
  }
};

export const Activity = (data) => {
  // return axios.post(Urls.logURL, {
  //   activity: "Session Logging",
  //   application: "Screen 0",
  //   ip: sessionStorage.getItem("ClientIP"),
  //   session_log: data
  // });
};

export const currentRequestTimeStamp = () => new Date().toISOString();
export const isMobile = () => {
  return window.innerWidth > 600 ? false : true;
};

export const transporterLogos = [
  {
    src: cj,
    title: "CJ Darcl",
    styles: { marginTop: "35px" },
  },
  {
    src: abr,
    title: "ABR Logistics",
  },
  {
    src: shahways,
    title: "Shahways",
    styles: { width: "70%", marginTop: "10px" },
  },
  {
    src: Varuna,
    title: "Varuna Logistics",
    styles: { maxWidth: "65%", marginTop: "55px" },
  },
  {
    src: ShreeManohar,
    title: "Shree Manohar Transporters",
    styles: { maxWidth: "65%", marginTop: "43px" },
  },
  {
    src: Ekart,
    title: "Ekart",
    styles: { maxWidth: "65%", marginTop: "40px" },
  },
  {
    src: ExpressRoadways,
    title: "Express Roadways",
    styles: { maxWidth: "65%", marginTop: "45px" },
  },
];

export const logos = [
  {
    src: PernardLogo,
    title: "Pernord Ricard",
    styles: {
      marginTop: "36px",
      maxWidth: "100px",
      width: "10%",
      visibility: "hidden",
    },
  },
  {
    src: PernardLogo,
    title: "Pernord Ricard",
    styles: { marginTop: "36px", maxWidth: "100px", width: "10%" },
  },
  {
    src: jyothy,
    title: "Jyothy Labs",
    styles: { marginTop: "16px", width: "10%", maxWidth: "10%" },
  },
  {
    src: Onida,
    title: "Onida",
    styles: { marginTop: "35px", width: "130px" },
  },
  {
    src: perfetti,
    title: "Perfetti Van Melle",
    styles: { marginTop: "30px", width: "145px" },
  },
  { src: bosch, title: "Bosch", styles: { marginTop: "0px" } },
  { src: hb, title: "Hector Beverages", styles: { width: "110px" } },
  {
    src: shell,
    title: "Shell",
    styles: { marginTop: "16px", width: "130px" },
  },
  {
    src: Schreiber,
    title: "Schreieber",
    styles: { marginTop: "30px", paddingLeft: "-20px" },
  },
  {
    src: SaintGobain,
    title: "Saint Gobain",
    styles: { marginTop: "30px", width: "130px" },
  },
  {
    src: Skechers,
    title: "Skechers",
    styles: { marginTop: "30px", maxWidth: "20%", width: "10%" },
  },
];

export const allLogos = [
  {
    src: PernardLogo,
    title: "Pernord Ricard",
    styles: {
      maxWidth: "180px",
      marginTop: "36px",
    },
  },
  {
    src: jyothy,
    title: "Jyothy Labs",
    styles: {
      maxWidth: "250px",
      marginTop: "16px",
    },
  },
  {
    src: Onida,
    title: "Onida",
    styles: {
      maxWidth: "150px",
      marginTop: "35px",
    },
  },
  {
    src: perfetti,
    title: "Perfetti Van Melle",
    styles: {
      maxWidth: "140px",
      marginTop: "30px",
    },
  },
  {
    src: bosch,
    title: "Bosch",
    styles: {
      maxWidth: "200px",
      marginTop: "0px",
    },
  },
  {
    src: hb,
    title: "Hector Beverages",
    styles: {
      maxWidth: "110px",
    },
  },
  {
    src: shell,
    title: "Shell",
    styles: {
      maxWidth: "130px",
      marginTop: "16px",
    },
  },
  {
    src: Schreiber,
    title: "Schreieber",
    styles: {
      // maxWidth: "220px",
      marginTop: "30px",
      paddingLeft: "-20px",
    },
  },
  {
    src: SaintGobain,
    title: "Saint Gobain",
    styles: {
      maxWidth: "150px",
      marginTop: "30px",
    },
  },
  {
    src: Skechers,
    title: "Skechers",
    styles: {
      // maxWidth: "100px",
      marginTop: "30px",
    },
  },
  {
    src: cj,
    title: "CJ Darcl",
    styles: {
      maxWidth: "160px",
      marginTop: "35px",
    },
  },
  {
    src: abr,
    title: "ABR Logistics",
    styles: {
      maxWidth: "100px",
    },
  },
  {
    src: shahways,
    title: "Shahways",
    styles: {
      maxWidth: "100px",
      marginTop: "10px",
    },
  },
  {
    src: Varuna,
    title: "Varuna Logistics",
    styles: {
      maxWidth: "210px",
      marginTop: "55px",
    },
  },
  {
    src: ShreeManohar,
    title: "Shree Manohar Transporters",
    styles: {
      maxWidth: "175px",
      marginTop: "43px",
    },
  },
  {
    src: Ekart,
    title: "Ekart",
    styles: {
      maxWidth: "160px",
      marginTop: "40px",
    },
  },
  {
    src: ExpressRoadways,
    title: "Express Roadways",
    styles: {
      maxWidth: "190px",
      marginTop: "45px",
    },
  },
];


export const casestudies = {

  jll: {
    title: "Jyothy Labs Limited",
    image: "../../Content/New LoRRI Assets/CaseStudy/fmcg.jpeg",
    color: "#393185",
    description:
      "Streamlining Success: Optimizing the Supply Chain of a Leading FMCG Company",
    background: {
      about:
        "Jyothy Labs is a prominent consumer goods company that specializes in manufacturing and marketing a wide range of household and personal care products. Founded in 1983 by M. P. Ramachandran, the company began its journey in India with the goal of providing high-quality and affordable products to consumers",
      image: require("../Content/images/Multimodal.jpg"),
    },
    impacts: [
      {
        id: 1,
        desc: "Spend aggregation across raw materials & finished goods",
        image: require("../Content/images/Multimodal.jpg"),
      },
      {
        id: 2,
        desc: "Optimized vendor mapping to spend base",
        image: require("../Content/images/Multimodal.jpg"),
      },
      {
        id: 3,
        desc: "Lot creation based on vendor interest, seamless and time bound auction",
        image: require("../Content/images/Multimodal.jpg"),
      },
    ],

    impact: {
      topContent:
        "Freight Procurement for primary transportation for a leading Indian company with primary focus on spend aggregation, optimization and discovering best fit carriers/transporters, driving collaborative value creation, leading to enhanced YoY savings.",
      impacts: [],
    },
    metrics: {},
    takeaways: "",
  },

};

export const CaseStudyCardData = [
  {
    id: 1,
    title: "Onida",
    companyName: "Onida",
    personName: "Nilesh Patil",
    designation: "Vice President - Global Supply Chain",
    embedVideoLink: "https://www.youtube.com/embed/77_eYlVvehE",
    ytVideoLink : "",
    thumbNailImage:
      "https://rnrdeploynew.blob.core.windows.net/staticimages/customer_ref_onida_thumbnail.jpg",
    status: true,
  },
  {
    id: 2,
    title: "Perfetti",
    companyName: "Perfetti Van Melle",
    personName: "Vaseem Ahmad",
    designation: "Associate Director (Logistics and Warehousing)",
    embedVideoLink: "https://www.youtube.com/embed/akErzBqu-d0",
    ytVideoLink : "",
    thumbNailImage:
      "https://rnrdeploynew.blob.core.windows.net/staticimages/customer_ref_pvm_thumbnail.jpg",
    status: true,
  },
  {
    id: 3,
    title: "Jyothy Labs",
    companyName: "Jyothy Labs",
    personName: "Ananth Rao",
    designation: "Head of Operations",
    embedVideoLink: "https://www.youtube.com/embed/T1sUqxHzX9c",
    ytVideoLink : "",
    thumbNailImage:
      "https://rnrdeploynew.blob.core.windows.net/staticimages/customer_ref_jll_thumbnail.jpg",
    status: true,
  },
  // {
  //   id: 4,
  //   title: "Hector Beverages",
  //   companyName: "Hector Beverages",
  //   personName : "Syamprasad Lakkaraju",
  //   designation : "Chief Supply Chain Officer",
  //   embedVideoLink: 'https://www.youtube.com/embed/O4IrOcbK4i0',
  // ytVideoLink : "",
  //   thumbNailImage : 'https://rnrdeploynew.blob.core.windows.net/staticimages/customer_ref_hector_thumbnail.jpg',
  //   status: true,
  // },
  {
    id: 5,
    title: "Saint Gobain",
    companyName: "Saint Gobain",
    personName: "Shekhar Kulkarni",
    designation: "Head of Supply Chain",
    embedVideoLink: "https://www.youtube.com/embed/dChAh9biv0g",
    ytVideoLink : "",
    thumbNailImage:
      "https://rnrdeploynew.blob.core.windows.net/staticimages/customer_ref_saint_gobain_thumbnail.jpg",
    status: true,
  },
];
