import React, { useEffect, useState } from "react";
import M from "materialize-css";
import "../Home/Home.css";
import AutoComplete from "../AutoComplete";
// import lorriLogo from "../Content/LoRRI.png";
import lorriLogo from "../Content/LoRRI_screen_zero.png";
// import MultipleItems from "../CarouselSlider/CarouselSlider";
import Scheduledemo from "../Scheduledemo";
// import lorri_small from "../Content/lorri-small.png";
import globe4 from "../Content/globe4.jpg";
import { Activity, currentRequestTimeStamp } from "./../Constants";
// import Axios from "axios";
import classes from "./../Searchtransporters/Searchtransporters.module.css";
import { Urls } from "../../Urls";
// import Modal from "../ModalBox";
import ReactGA from "react-ga";
// import Companies from "../Companies/Companies";
// import Companies2 from "../Companies/Companies2";
import LaunchSection from "../LaunchSection/LaunchSection";
// import ScheduleDetails from "../Scheduledemo/ScheduleDetails";
// import IntroSection from "../IntroSection/IntroSection";
import IntroSection from "../IntroSection/intro-sample";
// import NewsSection from "../NewsSection/NewsSection";
import mixpanel from "mixpanel-browser";
// import CompaniesCarousel from "../Companies/CarouselLogo";
import Companyclasses from "../Companies/Companies.module.css";
import Companies3 from "../Companies/Comapnies3";
import MobileLogos from "../Companies/MobileLogos";
import { NoAccessModal } from "../Searchtransporters/Modal";
import CaseStudySection from "../CaseStudy/CaseStudySection";

Activity([
  {
    screen: "Home",
    module: "Home",
    user_activity: "Home page visited",
    timestamp: currentRequestTimeStamp(),
  },
]);

// var streamingData = []

// const NoAccessPopUp = ({ closePopUp }) => {
//   return (
//     <div className={classes["no-access-container"]} id="no-access-container">
//       <div onClick={closePopUp} className={classes["close"]}>
//         <i className="material-icons">close</i>
//       </div>
//       <div className={classes["header"]}>
//         <h4>You have reached the maximum search limit!</h4>
//       </div>
//       <div className={classes["contact"]}>
//         <span>
//           Please Signup/Login on LoRRI to continue or{" "}
//           <a
//             href="https://company.lorri.in"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             click here
//           </a>
//         </span>
//       </div>
//     </div>
//   );
// };

// const CollectEmail = ({ inputHandler, submitHandler }) => {
//   return (
//     <div className="Email-Collection z-depth-2" id="email-container">
//       <div className="header">
//         <h4>Please provide your email to proceed!</h4>
//       </div>
//       <div className="body">
//         <input
//           type="text"
//           onChange={inputHandler}
//           placeholder="Ex: abc@abc.com"
//         />
//       </div>
//       <div className="action">
//         <button
//           className="btn waves-effect waves-light"
//           style={{ backgroundColor: "#393185 !important" }}
//           name="action"
//           onClick={submitHandler}
//         >
//           Submit
//           <i className="material-icons right">send</i>
//         </button>
//       </div>
//     </div>
//   );
// };

// const SlidingModal = ({ closeSlidingModal }) => {
//   return (
//     <div className="Sliding-modal s12 m12" id="Sliding-modal">
//       <div className="card " style={{ backgroundColor: "#393185" }}>
//         <div className="card-content white-text">
//           {window.innerWidth > 600 ? (
//             <>
//               <center>
//                 <span className={`${classes["s-heading"]}`}>
//                   COVID-19 SUPPORT
//                 </span>
//               </center>
//               <br />
//               <span className={`${classes["s-title"]}`}>
//                 Need trucks urgently? Please e-mail your urgent requirements to
//                 <a href="mailto:connect@logisticsnow.in?Subject=Urgent Requirement of Trucks">
//                   {" "}
//                   connect@logisticsnow.in
//                 </a>{" "}
//                 and call <a href="tel:+918655724402">+91-8655724402</a>
//               </span>
//               <hr />
//               <div className={`${classes["s-link"]}`}>
//                 <span className={`${classes["s-link"]}`}>
//                   <br />
//                   <a
//                     href="/Covid19ImpactReport"
//                     className={`${classes["s-link-title"]}`}
//                     onClick={() => {
//                       Activity([
//                         {
//                           screen: "Covid-Support",
//                           module: "Covid-Support",
//                           user_activity:
//                             "LogisticsNow COVID-19 Industry Impact Report Clicked",
//                           timestamp: currentRequestTimeStamp(),
//                         },
//                       ]);
//                       ReactGA.event({
//                         category: "LoRRI",
//                         action: `LogisticsNow COVID-19 Industry Impact Report Clicked`,
//                       });
//                       mixpanel.track(
//                         `User has clicked on LN Covid-19 Industry impact report`,
//                         {
//                           ApplicationName: `${Urls.mixpanelappname}`,
//                           ScreenName: "home",
//                           ModuleName: "landing_page",
//                           ActivityLog: `userClickedIndustryImpactReport`,
//                           Data: `None`,
//                           Definition: `User has clicked on LN Covid-19 Industry impact report`,
//                           ActivityType: "event_view",
//                         }
//                       );
//                     }}
//                   >
//                     <u>
//                       {" "}
//                       <b> LogisticsNow COVID-19 Industry Impact Report </b>{" "}
//                     </u>
//                   </a>
//                 </span>
//               </div>
//               <div className={`${classes["s-link"]}`}>
//                 <span className={`${classes["s-link"]}`}>
//                   <br />
//                   <a
//                     href="https://maps.mapmyindia.com/corona"
//                     className={`${classes["s-link-title"]}`}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     onClick={() => {
//                       Activity([
//                         {
//                           screen: "Covid-Support",
//                           module: "Covid-Support",
//                           user_activity: "Corona Information Dashboard Clicked",
//                           timestamp: currentRequestTimeStamp(),
//                         },
//                       ]);
//                       ReactGA.event({
//                         category: "LoRRI",
//                         action: `Corona Information Dashboard Clicked`,
//                       });
//                       mixpanel.track(
//                         `User has clicked on Corona Information dashboard`,
//                         {
//                           ApplicationName: `${Urls.mixpanelappname}`,
//                           ScreenName: "home",
//                           ModuleName: "landing_page",
//                           ActivityLog: `userClickedCoronaInformationDashboard`,
//                           Data: `None`,
//                           Definition: `User has clicked on Corona Information dashboard`,
//                           ActivityType: "event_view",
//                         }
//                       );
//                     }}
//                   >
//                     <u>
//                       {" "}
//                       <b> Corona Information Dashboard</b>{" "}
//                     </u>
//                   </a>
//                 </span>
//               </div>
//             </>
//           ) : (
//             <>
//               <center>
//                 <span className={`${classes["s-heading"]}`}>
//                   COVID-19 SUPPORT
//                 </span>
//               </center>
//               <br />
//               <span>
//                 Need trucks urgently? Please e-mail your urgent requirements to
//                 <a href="mailto:connect@logisticsnow.in?Subject=Urgent Requirement of Trucks">
//                   {" "}
//                   connect@logisticsnow.in
//                 </a>{" "}
//                 and call <a href="tel:+918655724402">+91-8655724402</a>
//               </span>
//               <hr />

//               <div className={`${classes["s-info"]}`}>
//                 <span className={`${classes["s-link"]}`}>
//                   <a
//                     href="/Covid19ImpactReport"
//                     className={`${classes["s-link-title"]}`}
//                     onClick={() => {
//                       Activity([
//                         {
//                           screen: "Covid-Support",
//                           module: "Covid-Support",
//                           user_activity:
//                             "LogisticsNow COVID-19 Industry Impact Report Clicked",
//                           timestamp: currentRequestTimeStamp(),
//                         },
//                       ]);
//                       ReactGA.event({
//                         category: "LoRRI",
//                         action: `LogisticsNow COVID-19 Industry Impact Report Clicked`,
//                       });
//                       mixpanel.track(
//                         `User has clicked on LN Covid-19 Industry impact report`,
//                         {
//                           ApplicationName: `${Urls.mixpanelappname}`,
//                           ScreenName: "home",
//                           ModuleName: "landing_page",
//                           ActivityLog: `userClickedIndustryImpactReport`,
//                           Data: `None`,
//                           Definition: `User has clicked on LN Covid-19 Industry impact report`,
//                           ActivityType: "event_view",
//                         }
//                       );
//                     }}
//                   >
//                     <u>
//                       {" "}
//                       <b>LogisticsNow COVID-19 Industry Impact Report </b>{" "}
//                     </u>
//                   </a>
//                 </span>
//               </div>
//               <div className={`${classes["s-info"]}`}>
//                 <span className={`${classes["s-link"]}`}>
//                   <br />
//                   <a
//                     href="https://maps.mapmyindia.com/corona"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className={`${classes["s-link-title"]}`}
//                     onClick={() => {
//                       Activity([
//                         {
//                           screen: "Covid-Support",
//                           module: "Covid-Support",
//                           user_activity: "Corona Information Dashboard Clicked",
//                           timestamp: currentRequestTimeStamp(),
//                         },
//                       ]);
//                       ReactGA.event({
//                         category: "LoRRI",
//                         action: `Corona Information Dashboard Clicked`,
//                       });
//                       mixpanel.track(
//                         `User has clicked on Corona Information dashboard`,
//                         {
//                           ApplicationName: `${Urls.mixpanelappname}`,
//                           ScreenName: "home",
//                           ModuleName: "landing_page",
//                           ActivityLog: `userClickedCoronaInformationDashboard`,
//                           Data: `None`,
//                           Definition: `User has clicked on Corona Information dashboard`,
//                           ActivityType: "event_view",
//                         }
//                       );
//                     }}
//                   >
//                     <u>
//                       {" "}
//                       <b> Corona Information Dashboard</b>{" "}
//                     </u>
//                   </a>
//                 </span>
//               </div>
//             </>
//           )}
//         </div>
//         <div onClick={closeSlidingModal} className="card-action" style={{ cursor: "pointer", textAlign: "center" }}>
//           <span className={classes['s-close']}>Close</span>
//         </div>

//       </div>
//     </div>
//   );
// };

const Home = (props) => {
  // const [streamingData, setstreamingData] = useState([]);
  const [locationValue, setlocationValue] = useState("");
  useEffect(() => {
    ReactGA.pageview("Home");
    mixpanel.track(`User has viewed home page`, {
      ApplicationName: `${Urls.mixpanelappname}`,
      ScreenName: "home",
      ModuleName: "landing_page",
      ActivityLog: `userViewedHomePage`,
      Data: `None`,
      Definition: `User has viewed home page`,
      ActivityType: "page_view",
    });
    // Axios.get(Urls.streamingData)
    //   .then(res => {
    //     setstreamingData(res.data.streaming_data ? res.data.streaming_data : [])
    //     // setstreamingData([{"message" : "10 trucks available from Mumbai, Maharashtra"}, {"message" : "27 trucks available from Pune, Maharashtra"},{"message" : "10 trucks available from Mumbai, Maharashtra"},{"message" : "10 trucks available from Mumbai, Maharashtra"},{"message" : "10 trucks available from Mumbai, Maharashtra"}])
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    let elems = document.querySelectorAll(".parallax");

    // By default if you want to open covid-19 slider
    // window.innerWidth > 600 ? (
    //   document.getElementById('Sliding-modal').style.right = '0')
    //   : (document.getElementById('Sliding-modal').style.right = '0')

    M.Parallax.init(elems);
    // document.getElementById("custom-input-suggest-id").focus();

    let elem_bottom_modal = document.querySelectorAll(".modal");
    M.Modal.init(elem_bottom_modal);

    var options = {};

    // M.toast({ html: toastHTML, displayLength: 15000 });

    // load feature discovery
    var elems2 = document.querySelectorAll(".tap-target");
    M.TapTarget.init(elems2, options);

    // if(sessionStorage.getItem('totalSearches')> 3 && sessionStorage.getItem('isEmailExist') !== 'yes') {
    //   setIsNoAcsessModalClosable(true);
    //   setShowNoAccess(true);
    // }
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const [email, setEmail] = useState("");
  // const [searchedText, setSearchedText] = useState();
  // const [display, setdisplay] = useState(true);
  const [showNoAccess, setShowNoAccess] = useState(false);
  const [isNoAcsessModalClosable, setIsNoAcsessModalClosable] = useState(true)
  const [isSuperUser, setIsSuperUser] = useState(sessionStorage.getItem('isEmailExist') === "yes")

  const inputHandler = (event) => {
    setEmail(event.target.value);
  };

  // const closePopUp = () => {
  //   document.getElementById("no-access-container").style.top = "-100%";
  // };

  // const submitHandler = () => {
  //   // console.log(email)
  //   sessionStorage.setItem("isEmailExist", "yes");
  //   Activity([
  //     {
  //       screen: "Home",
  //       module: "Search Bar",
  //       user_activity: {
  //         type: "New User",
  //         value: email,
  //       },
  //       timestamp: currentRequestTimeStamp(),
  //     },
  //   ]);
  //   ReactGA.event({
  //     category: "Home",
  //     action: `Location searched and email provided`,
  //   });

  //   // setSearchedText(locationValue);
  //   props.history.push({
  //     pathname: "/searchtransporters",
  //     state: locationValue,
  //   });
  //   Activity([
  //     {
  //       screen: "Home",
  //       module: "Search Bar",
  //       user_activity: locationValue,
  //       timestamp: currentRequestTimeStamp(),
  //     },
  //   ]);
  //   // console.log(locationValue.location_name)
  //   mixpanel.track(`User has searched for a location`, {
  //     ApplicationName: `${Urls.mixpanelappname}`,
  //     ScreenName: "home",
  //     ModuleName: "landing_page",
  //     ActivityLog: `userSearchedSearchBar`,
  //     Data: `${locationValue}`,
  //     Definition: `User has searched for a location`,
  //     ActivityType: "event_view",
  //   });
  // };

  const SearchLocation = (value) => {
    let totalSearches = parseInt(sessionStorage.getItem("totalSearches"), 10) || 0;
    setlocationValue(value);
    
    if(!totalSearches) {
      sessionStorage.setItem("totalSearches", 0);
    }

    const moveToSearch = () => {
      props.history.push({ pathname: "/searchtransporters", state: value });
      Activity([
        {
          screen: "Home",
          module: "Search Bar",
          user_activity: value,
          timestamp: currentRequestTimeStamp(),
        },
      ]);
      ReactGA.event({
        category: "Home",
        action: `Search Bar`,
      });
      mixpanel.track(`User has searched for a location`, {
        ApplicationName: `${Urls.mixpanelappname}`,
        ScreenName: "home",
        ModuleName: "landing_page",
        ActivityLog: `userSearchedSearchBar`,
        Data: `${value.location_name}`,
        Definition: `User has searched for a location`,
        ActivityType: "event_view",
      });
    }

    if (isSuperUser || totalSearches < 3) {
        moveToSearch();
    } else {
        setShowNoAccess(true);
        setIsNoAcsessModalClosable(true);
    }
  };

  const handleClose = () => {
    setShowNoAccess(false)
  }

  // const openSlidingModal = () => {
  //   Activity([
  //     {
  //       screen: "Covid-Support",
  //       module: "Covid-Support",
  //       user_activity: "Covid-19 SideButton Clicked",
  //       timestamp: currentRequestTimeStamp(),
  //     },
  //   ]);
  //   ReactGA.event({
  //     category: "Home",
  //     action: `Covid-19 SideButton Clicked`,
  //   });
  //   window.innerWidth > 600
  //     ? (document.getElementById("Sliding-modal").style.right = "0")
  //     : (document.getElementById("Sliding-modal").style.right = "0");
  // };
  // const closeSlidingModal = () => {
  //   Activity([
  //     {
  //       screen: "Covid-Support",
  //       module: "Covid-Support",
  //       user_activity: "Covid-19 Close Clicked",
  //       timestamp: currentRequestTimeStamp(),
  //     },
  //   ]);
  //   ReactGA.event({
  //     category: "Home",
  //     action: `Covid-19 Close Clicked`,
  //   });
  //   // window.innerWidth > 600
  //   //   ? (document.getElementById("Sliding-modal").style.right = "-75%")
  //   //   : (document.getElementById("Sliding-modal").style.right = "-400%");
  // };

  return (
    <div>
      {/* <CollectEmail inputHandler={inputHandler} submitHandler={submitHandler} /> */}
      {/* <SlidingModal closeSlidingModal={closeSlidingModal} /> */}
      {/* <NoAccessPopUp closePopUp={closePopUp} /> */}
      <NoAccessModal 
        show={showNoAccess}
        isClosable={isNoAcsessModalClosable}
        handleClose={handleClose}
      />
      <section className="first-section">
        <div className={`parallax-container`}>
          {/* <div className={`parallax transparent ${classes['s-pcontainer']}`}> */}
          <div className={`parallax transparent`}>
            {/* <img width='auto' height="680px" alt="img" src={globe4} /> */}
            <img alt="img" src={globe4} />
          </div>

          <div className="show-on-lg-screen">
            <div className="logo-div">
              <a
                href="https://company.lorri.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <img alt="logo" width="auto" height="190px" src={lorriLogo} className="logo-img" /> */}
                <img alt="logo" src={lorriLogo} className="logo-img" />
              </a>
            </div>

            <div className="custom-autosuggest-container-flex autocomplete-container">
              <AutoComplete
                onSelect={(value) => SearchLocation(value)}
                onChange={(value) => setSearchInput(value)}
                value={searchInput}
                inputClassName="Autosuggest-input"
                placeholder={`Search locations, connect the digital dots!`}
                id="custom-input-suggest-id"
              />
            </div>

            <div className={`${classes["s-stats"]}`}>
              <center>
                <span>
                  <b>700+ Districts</b>
                  <b className={`${classes["s-stats-line"]}`}> | </b>
                </span>
                <span>
                  <b>50,000+ Lanes</b>
                  <b className={`${classes["s-stats-line"]}`}> | </b>
                </span>
                <span>
                  <b>1700+ Transporters</b>
                </span>
              </center>
            </div>
            <div style={{ paddingBottom: "12px" }}>
              <center>
                <a
                  className="waves-effect btn"
                  style={{
                    marginRight: "1%",
                    backgroundColor: "white",
                    marginTop: "20px",
                    fontSize: "13px",
                    paddingLeft: "10px",
                    paddingRight: "0px",
                    border: "1px solid #54af3a",
                  }}
                  id="discover_more"
                  href={
                    window.location === "/"
                      ? "#schedule-form"
                      : "/#schedule-form"
                  }
                  onClick={() => {
                    Activity([
                      {
                        screen: "Home",
                        module: "Schedule Demo Button",
                        user_activity: "Schedule Demo Button Clicked",
                        timestamp: currentRequestTimeStamp(),
                      },
                    ]);
                    ReactGA.event({
                      category: "Header",
                      action: `Schedule Demo Button Clicked`,
                    });

                    mixpanel.track(`User has clicked on Schedule Demo button`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: "home",
                      ModuleName: "landing_page",
                      ActivityLog: `userClickedScheduleDemoButton`,
                      Data: `None`,
                      Definition: `User has clicked on Schedule Demo button`,
                      ActivityType: "event_view",
                    });
                    // setdisplay(true);
                  }}
                  rel="noopener noreferrer"
                >
                  <b style={{ color: "#393185" }}>
                    SCHEDULE MEETING/ DEMO NOW{" "}
                    <i
                      className="material-icons right"
                      style={{ color: "#393185", fontSize: "24px" }}
                    >
                      keyboard_arrow_right
                    </i>
                  </b>
                  
                  {/* <i className="material-icons right" style={{color: '#393185', fontSize: '30px'}}>keyboard_arrow_right</i> */}
                </a>
                
              </center>
            </div>
          </div>

          {/* display set to none when not on mobile */}
          <div className="show-on-mobile">
            <div className="logo-div">
              <a
                href="https://company.lorri.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="img" src={lorriLogo} className="logo-img" />
              </a>
            </div>
            <div className="custom-autosuggest-container-flex autocomplete-container-mobile">
              <AutoComplete
                onSelect={(value) => SearchLocation(value)}
                onChange={(value) => setSearchInput(value)}
                value={searchInput}
                inputClassName="Autosuggest-input"
                placeholder="Search locations, connect the digital dots!"
                id="custom-input-suggest-id"
              />
            </div>
            <div className={`${classes["s-stats-cont"]}`}>
              <center>
                <span>
                  <b className={`${classes["s-stats"]}`}>700+ Districts</b>
                  <b className={`${classes["s-stats-line"]}`}> | </b>
                </span>
                <span>
                  <b className={`${classes["s-stats"]}`}>50,000+ Lanes</b>
                  <b className={`${classes["s-stats-line"]}`}> | </b>
                </span>
                <span>
                  <b className={`${classes["s-stats"]}`}>1700 + Transporters</b>
                </span>
              </center>
            </div>
            <center>
              <div className={`${classes["s-event"]}`}>
                <a
                  // data-target="modal1"
                  href={
                    window.location === "/"
                      ? "#schedule-form"
                      : "/#schedule-form"
                  }
                  className={`${classes["s-pevents"]} modal-trigger btn header-font-size z-depth-2`}
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "Schedule Demo Button",
                        user_activity: "Schedule Demo Button Clicked",
                        timestamp: currentRequestTimeStamp(),
                      },
                    ]);
                    ReactGA.event({
                      category: "Header",
                      action: `Schedule Demo Button Clicked`,
                    });
                    mixpanel.track(`User has clicked on Schedule Demo button`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: "home",
                      ModuleName: "landing_page",
                      ActivityLog: `userClickedScheduleDemoButton`,
                      Data: `None`,
                      Definition: `User has clicked on Schedule Demo button`,
                      ActivityType: "event_view",
                    });
                  }}
                >
                  <i className="material-icons left">event_available</i>
                  SCHEDULE MEETING/ DEMO NOW
                  <i className="material-icons right">chevron_right</i>
                </a>
                <a
                  href="https://company.lorri.in"
                  target="_blank"
                  id="company-login-link"
                  rel="noopener noreferrer"
                  className="waves-effect btn-small"
                  style={{ marginTop: "2%" }}
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "Header",
                        user_activity: "Manufacturer Login Button Clicked",
                        timestamp: currentRequestTimeStamp(),
                      },
                    ]);
                    ReactGA.event({
                      category: "Header",
                      action: `Manufacturer Login Button Clicked`,
                    });

                    mixpanel.track(
                      `User has clicked on Manufacturer Login button`,
                      {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: "home",
                        ModuleName: "header_element",
                        ActivityLog: `userClickedManufacturerLoginButton`,
                        Data: `None`,
                        Definition: `User has clicked on Manufacturer Login button`,
                        ActivityType: "event_view",
                      }
                    );
                  }}
                >
                  <i className="material-icons left">person</i>
                  <b>Manufacturer Login</b>
                </a>
                <a
                  href="https://transporter.lorri.in"
                  target="_blank"
                  id="company-login-link"
                  rel="noopener noreferrer"
                  className="waves-effect btn-small"
                  style={{ marginTop: "2%" }}
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "Header",
                        user_activity: "Transporter Login Button Clicked",
                        timestamp: currentRequestTimeStamp(),
                      },
                    ]);
                    ReactGA.event({
                      category: "Header",
                      action: `Transporter Login Button Clicked`,
                    });

                    mixpanel.track(
                      `User has clicked on Transporter/LSP Login button`,
                      {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: "home",
                        ModuleName: "header_element",
                        ActivityLog: `userClickedTransporterLoginButton`,
                        Data: `None`,
                        Definition: `User has clicked on Transporter/LSP Login button`,
                        ActivityType: "event_view",
                      }
                    );
                  }}
                >
                  <i className="material-icons left">local_shipping</i>
                  <b>Transporter Login</b>
                </a>
              </div>
            </center>
          </div>
        </div>
      </section>

      <section
        className={`carousel-section ${Companyclasses["show-on-lg-screen"]}`}
      >
        <Companies3 />
      </section>

      <section className={Companyclasses["show-on-mobile"]}>
        {/* <CompaniesCarousel /> */}
        <MobileLogos />
      </section>

      <hr />
      {/* <section className="carousel-section">
        <IntroSection />
      </section> */}

      <section className="carousel-section">
        <IntroSection />
      </section>
      <hr />
      {/* <section className="news-section">
        <NewsSection />
      </section> */}
      <div>
          <CaseStudySection/>
        </div>
      <hr />
      <section className="launch-section">
        <LaunchSection />
      </section>
      {/* {window.innerWidth > 600 ? */}
      <div
        className="schedule-section"
        style={{ justifyContent: "center", width: "100%", textAlign: "center" }}
      >
        <div className="schedule-time">
          <Scheduledemo setIsSuperUser={setIsSuperUser}/>
        </div>
      </div>
      {/* : */}
      <>
      </>
      {/* } */}
    </div>
  );
};
export default Home;
