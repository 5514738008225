import React, { useEffect, useState } from "react";
import classes from "./Companies.module.css";
import { allLogos } from "../Constants";
import Marquee from "react-fast-marquee";

const Companies = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getGradientSize = (screenWidth) => {
    if (screenWidth > 800) {
      return 200;
    } else if (screenWidth > 600) {
      return 100;
    } else {
      return 50;
    }
  };
  const maxHeightCustom = (title) => {
    if (title === "Skechers") {
      return "125px";
    } else if (title === "Hector Beverages") {
      return "120px";
    } else if (title === "ABR Logistics") {
      return "127px";
    }
    return "100px";
  };

  return (
    <div className={classes["main-div"]}>
      <hr className={`hr-companies`} />
      <center className={classes["heading"]} style={{ marginBottom: 0 }}>
        LoRRI makes a difference
      </center>
      <div style={{ margin: "15px 5% 30px" }}>
        <Marquee
          className={classes["logo-marquee"]}
          pauseOnHover
          gradient
          gradientWidth={getGradientSize(screenWidth)}
          speed={100}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginLeft: "40px", marginRight: "50px" }}></div>
            {allLogos.map((logo, index) => (
              <div
                key={index}
                className={classes["logo-item"]}
                style={{ marginLeft: "20px", marginRight: "20px" }}
              >
                <img
                  alt="img"
                  src={logo.src}
                  style={{
                    ...logo.styles,
                    maxHeight: maxHeightCustom(logo.title),
                    height: "auto",
                    marginTop: 0,
                  }}
                  title={logo.title}
                  className={classes["responsive-logos"]}
                />
              </div>
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Companies;
