import React, { useEffect, useState } from "react";
// import Map from "./Map";
import Axios from "axios";
import M from "materialize-css";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import Rating from "react-rating";
import { Urls } from "../../Urls";
import Loader from "../Loader";
import { Activity, currentRequestTimeStamp, encryptor } from "./../Constants";
import Map2 from "./Map/Map2";
import { CollectEmailModal, NoAccessModal } from "./Modal";
import Searchbar from "./Searchbar";
import classes from "./Searchtransporters.module.css";
import Transporterlist from "./Transporterlist";

const Searchtransporters = (props) => {
  const [searchedInput, setsearchedInput] = useState(
    props.history.location.state ? props.history.location.state : ""
  );
  const [transporterList, setTransporterList] = useState([]);
  const [network, setNetwork] = useState({});
  const [email, setEmail] = useState("");
  const [dashboard, setDashboard] = useState([]);
  const [searched, setSearched] = useState([]);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [number, setnumber] = useState("");
  const [showCollectEmail, setShowCollectEmail] = useState(false);
  const [showNoAccess, setShowNoAccess] = useState(false);
  const [isNoAcsessModalClosable, setIsNoAcsessModalClosable] = useState(true)
  const [isCollectEmailModalClosable, setIsCollectEmailModalClosable] = useState(true)
  const [isSuperUser, setIsSuperUser] = useState(sessionStorage.getItem('isEmailExist') === "yes")

  const submitHandler = (e) => {
    e.preventDefault();
    if (email === "" || !validateEmail(email)) {
    alert("Please enter a valid email address");
    } else {
      setShowNoAccess(false);
      setShowCollectEmail(false);
      sessionStorage.setItem("isEmailExist", "yes");
      setIsSuperUser(true)
      Activity([
        {
          screen: "Home",
          module: "Search Bar",
          user_activity: {
            type: "New User",
            value: `${email},${number}`,
          },
          timestamp: currentRequestTimeStamp(),
          ip: sessionStorage.getItem("ClientIP"),
        },
      ]);
      sendEmail(email);
    }
  };

  const searchLocation = (location) => {
    const totalSearches = parseInt(sessionStorage.getItem("totalSearches") || 0) + 1;
    sessionStorage.setItem("totalSearches", totalSearches);
  
    if (!isSuperUser && totalSearches > 3) {
      setIsCollectEmailModalClosable(false);
      setShowCollectEmail(true);
      return;
    }
  
    if (!location) return;
  
    setTransporterList([]);
    setNetwork({});
    setSearched([]);
    setDashboard([]);
    setShow(true);
  
    // Fetch location data
    Axios.get(`${Urls.GetLocations}?lat=${location.location.lat}&lon=${location.location.lon}`)
      .then((res) => {
        if (res.data) {
          setShow(false);
          setTransporterList(res.data.transporter_list);
          setNetwork(res.data.network);
          setSearched(res.data.searched_coordinates);
          setDashboard(res.data.dashboard);
  
          if (!isSuperUser) {
            setCount(prevCount => prevCount + 1);
            if (count + 1 > 3) {
              setShowNoAccess(true);
            }
          }
        }
      })
      .catch((err) => {
        setShow(false);
        console.error(err);
      });
  };
  
  const searchMyLocation = (value) => {
    setsearchedInput(value);
    const totalSearches = parseInt(sessionStorage.getItem("totalSearches") || 0) + 1;
    sessionStorage.setItem("totalSearches", totalSearches);
    
    if (!isSuperUser && totalSearches > 3) {
      setIsCollectEmailModalClosable(false);
      setShowCollectEmail(true);
      return;
    }
    
    searchLocation(value);
    Activity([
      {
        screen: "Search Transporter",
        module: "Search Bar",
        user_activity: value,
        timestamp: currentRequestTimeStamp(),
      },
    ]);

    mixpanel.track(`User has searched for a location`, {
      ApplicationName: `${Urls.mixpanelappname}`,
      ScreenName: "search_transporter_page",
      ModuleName: "search_transporter_page",
      ActivityLog: `userSearchedSearchBar`,
      Data: `${value.location_name}`,
      Definition: `User has searched for a location`,
      ActivityType: "event_view",
    });
  };

  const getEmailBody = function (user, newUser) {
    let emailBody = {
      from_email: `lorri@logisticsnow.in`,
      subject: `New User on ScreenZero`,
      to_email: user,
      content: `<h4>Hello, </br></br>${newUser} has visited the LoRRI Home (ScreenZero)!</br>
            <div>
            Email ID is: ${email}<br/>
            Phone Number is:${number}
            </div></br>
            </br>Thanks</h4>`,
      email_status: "Pending",
      activity: "Email Request",
    };
    return emailBody;
  };

  const emailinputHandler = (event) => {
    setEmail(event.target.value);
  };

  const numberinputHandler = (event) => {
    setnumber(event.target.value);
  };

  const sendEmail = async (userEmail) => {
    const users = [
      "connect@logisticsnow.in",
      "raj@logisticsnow.in",
      "radhika@logisticsnow.in",
      "associate@logisticsnow.in",
    ];
  
    try {
      await Promise.all(users.map(user => 
        Axios.post(Urls.log, getEmailBody(user, userEmail))
      ));
    } catch (error) {
      console.error('Error sending emails:', error);
    }
  };

  function validateEmail(email) {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    ReactGA.pageview("SearchTransporter");
    document.title = "LoRRI - Search Transporter";
    // setsearchedInput(props.history.location.state?props.history.location.state.label:'')
    searchLocation(searchedInput);
    mixpanel.track(`User has viewed search transporter page`, {
      ApplicationName: `${Urls.mixpanelappname}`,
      ScreenName: "search_transporter_page",
      ModuleName: "search_transporter_page",
      ActivityLog: `userViewedSearchTransporterPage`,
      Data: `None`,
      Definition: `User has viewed search transporter page`,
      ActivityType: "page_view",
    });
    let elems2 = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems2);
    
  }, []);

  useEffect(() => {
    let intervalId;
    const totalSearches = parseInt(sessionStorage.getItem('totalSearches'), 10) || 0;

    if (!isSuperUser && totalSearches > 3) {
      setIsCollectEmailModalClosable(false);
      setShowCollectEmail(true);
    } else if (!isSuperUser) {
      intervalId = setInterval(() => {
        if(!isSuperUser) {
          setIsCollectEmailModalClosable(true);
          setShowCollectEmail(true); 
        }
      }, 15000);
    }
    return () => clearInterval(intervalId);
  },[isSuperUser])

  return (
    <div style={{ marginTop: window.innerWidth > 600 ? "4%" : "-13%" }}>
      <CollectEmailModal
        show={showCollectEmail}
        handleClose={() => setShowCollectEmail(false)}
        emailinputHandler={emailinputHandler}
        numberinputHandler={numberinputHandler}
        submitHandler={submitHandler}
        isClosable={isCollectEmailModalClosable}
      />
      <NoAccessModal
        show={showNoAccess}
        handleClose={() => setShowNoAccess(false)}
        isClosable={isNoAcsessModalClosable}
      />
      <div id="SearchContainer" className={`${classes["SearchContainer"]}`}>
        <Loader show={show} />
        {window.innerWidth >= 900 ? (
          <>
            <div className={`row ${classes["SearchBarContainer"]}`}>
              <span className={`${classes["searched_text"]}`}>
                Search another plant location here:
              </span>
              <Searchbar
                searchedInput={searchedInput}
                searchLocation={(value) => searchMyLocation(value)}
              />
            </div>
          </>
        ) : (
          <div
            className={`row ${classes["SearchBarContainer"]}`}
            style={{ display: "inline" }}
          >
            <center
              style={{ marginTop: window.innerWidth > 600 ? "0%" : "18%" }}
            >
              <span
                className={`${classes["searched_text"]}`}
                style={{ marginLeft: 0 }}
              >
                Search another plant location here:
              </span>
              <Searchbar
                searchedInput={searchedInput}
                searchLocation={(value) => searchMyLocation(value)}
              />
            </center>
          </div>
        )}

        <div className="row" style={{ marginTop: "2%" }}>
          <div className="col s12 m12 l7 xl7">
              <div className={classes.showOnMobile}>
                <ul className="collapsible">
                  <li>
                    <div
                      className="collapsible-header"
                      style={{
                        justifyContent: "center",
                        display: "grid",
                        padding: "0%",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "large",
                          color: "#393185",
                          textAlign: "center",
                        }}
                      >
                        <b>
                          {`Transporters in ${
                            searchedInput.location && searchedInput.location.label
                              ? searchedInput.location.label.split(",")[0]
                              : ""
                          }`}
                        </b>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center ",
                        }}
                      >
                        <b>
                          Showing {transporterList.length} of{" "}
                          <span style={{ fontSize: "20px", fontWeight: 600 }}>
                            {dashboard && dashboard.length > 0
                              ? dashboard[0].value
                              : 0}
                          </span>{" "}
                          transporters
                        </b>
                        <i className="material-icons">arrow_drop_down</i>
                      </div>
                    </div>
                    <div className="collapsible-body">
                      {transporterList.map((item) => (
                        <ul key={item.transporter_id}>
                          <li style={{ textAlign: "center" }}>
                            <a
                              href={`https://company.lorri.in/tpmp/${encryptor(
                                item.transporter_id,
                                true
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#393185" }}
                              onClick={() => {
                                mixpanel.track(
                                  `User has clicked on transporter profile`,
                                  {
                                    ApplicationName: `${Urls.mixpanelappname}`,
                                    ScreenName: "search_transporter_page",
                                    ModuleName: "search_transporter_page",
                                    ActivityLog: `userClickedTransporterProfile`,
                                    Data: `${item.transporter_name}`,
                                    Definition: `User has clicked on transporter profile`,
                                    ActivityType: "event_view",
                                  }
                                );
                              }}
                            >
                              {item.transporter_name.toUpperCase()}
                            </a>
                          </li>
                          <li style={{ textAlign: "center" }}>
                            <Rating
                              initialRating={item.overall_rating}
                              fullSymbol={
                                <i
                                  className="material-icons"
                                  style={{ color: "orange" }}
                                >
                                  star
                                </i>
                              }
                              emptySymbol={
                                <i
                                  className="material-icons"
                                  style={{ color: "orange" }}
                                >
                                  star_border
                                </i>
                              }
                              readonly="true"
                            />
                          </li>
                          <hr />
                        </ul>
                      ))}
                      <center>
                        <div>
                          <a
                            onClick={() => {
                              ReactGA.event({
                                category: "SearchTransporter",
                                action: `Signup Clicked`,
                              });
                              mixpanel.track(
                                `User has clicked on signup button`,
                                {
                                  ApplicationName: `${Urls.mixpanelappname}`,
                                  ScreenName: "search_transporter_page",
                                  ModuleName: "search_transporter_page",
                                  ActivityLog: `userClickedSignupButton`,
                                  Data: `None`,
                                  Definition: `User has clicked on signup button`,
                                  ActivityType: "event_view",
                                }
                              );
                            }}
                            href="https://company.lorri.in"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#393185" }}
                          >
                            <u>Sign Up</u>
                          </a>
                        </div>
                      </center>
                    </div>
                  </li>
                </ul>
              </div>
            {/* <Map
              inflow={network.inflow}
              outflow={network.outflow}
              searched={searched}
            /> */}

            <Map2
              inflow={network.inflow}
              outflow={network.outflow}
              searched={searched}
            />
          </div>
            <div className={classes.showOnLargeScreen}>
              <div className="col s12 m12 l5 xl5">
                <Transporterlist
                  transporters={transporterList}
                  dashboard={dashboard}
                  locationName={searchedInput}
                  history={props.history}
                />
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Searchtransporters;
