import React from "react";
// import { CardMedia, Grid } from "@material-ui/core";
import classes from "../Companies/Companies.module.css";

const LaunchSection = () => {
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //  only for yt embed
  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenWidth(window.innerWidth);
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // Calculate video width based on screen size
  // const calculateVideoWidth = () => {
  //   if (screenWidth >= 1200) {
  //     return "150%";
  //   } else if (screenWidth >= 600) {
  //     return "120%";
  //   } else {
  //     return "100%";
  //   }
  // };
  return (
    <div>
      <div className={classes["show-on-lg-screen"]}>
        <center>
          <b className={classes["heading"]} style={{ paddingLeft: "5%" }}>
            LoRRI launch at ELSC 2019, Taj Lands End
          </b>
        </center>
        <div
          style={{
            display: "flex",
            height: "max-content",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "4%",
          }}
        >
          <div className={`${classes["text"]} ${classes["launch-text"]}`}>
            LogisticsNow formally launched its flagship product LoRRI receiving
            wide acclaim from leading Indian and global companies with over 40+
            companies attending Products demo at the LogisticsNow booth.
          </div>
          <div
            id="youtube_video"
            // className="row"
            style={{
              padding: "0%",
              width: '50%',
              borderRadius: "1%",
              marginTop: "2%",
            }}
          >
            {/* <video className="responsive-video" controls>
                        <source width="100%" height="280px" src="https://www.youtube.com/watch?v=vf3baRV6QW8?autoplay=1&mute=1" type="video/mp4"></source>
                    </video> */}
            {/* <iframe width="100%" height="280px" src="https://www.youtube.com/embed?v=vf3baRV6QW8?rel=0" allowFullScreen frameborder="0" ></iframe> */}
            <iframe
              width={"90%"}
              height="315"
              src="https://www.youtube.com/embed/vf3baRV6QW8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className={classes["show-on-mobile"]}>
        <center>
          <b className={classes["heading"]} style={{ paddingLeft: "5%" }}>
            LoRRI LAUNCH AT ELSC 2019, Taj Lands End
          </b>
          <div style={{ margin: "5%" }}>
            <span className={classes["text1"]}>
              LogisticsNow formally launched its flagship product LoRRI
              receiving wide acclaim from leading Indian and global companies
              with over 40+ companies attending Products demo at the
              LogisticsNow booth.
            </span>
          </div>
          <div style={{ margin: "4%" }} className="videowrapper">
            <iframe
              height="auto"
              width="260px"
              src="https://www.youtube.com/embed/vf3baRV6QW8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </center>
      </div>
    </div>
  );
};

export default LaunchSection;
