import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import Searchtransporters from './Components/Searchtransporters';
// import SampleProfile from './Components/SampleProfile';
import Covid19ImpactReport from './Components/Covid19ImpactReport/Covid19ImpactReport';
import CoronaInformationDashboard from './Components/CoronaInformationDashboard/CoronaInformationDashboard';
// import TruckAvailability from './Components/TruckAvailability/TruckAvailability';

const RouterOutlet = () => {
  return (
    <Switch>
      <Route path="/Home" component={Home} exact />
      <Route path="/" component={Home} exact />
      <Route path="/searchtransporters" component={Searchtransporters} exact />
      {/* <Route path="/sampleProfile" component={SampleProfile} exact /> */}
      <Route path="/Covid19ImpactReport" component={Covid19ImpactReport} exact />
      <Route path="/CoronaInformationDashboard" component={CoronaInformationDashboard} exact />
      {/* <Route path="/TruckAvailability" component={TruckAvailability} exact /> */}
    </Switch>
  );
};

export default RouterOutlet;
