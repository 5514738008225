/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
// import Modal from "../ModalBox";
import M from "materialize-css";
import "../Header/Header.css";
import { withRouter } from "react-router-dom";
// import LN from '../Content/LN-favicon.png';
import LN from "../Content/LN-logo.png";
// import Scheduledemo from "../Scheduledemo";
// import lorri_small from "../Content/lorri-small.png";
import { Activity, currentRequestTimeStamp } from "./../Constants";
import ClickOutSideContext from "../ClickOutSide/ClickOutSideContext";
import ClickOutSide from "../ClickOutSide";
// import new_gif from '../Content/new.gif';
// import NotificationBadge from 'react-notification-badge';
// import { Effect } from 'react-notification-badge';
// import Axios from 'axios'
import { Urls } from './../../Urls'
import ReactGA from 'react-ga'
import classes from './../Searchtransporters/Searchtransporters.module.css'
// import {LinkedInIcon} from '@material-ui/icons';
// import { Icon } from '@material-ui/core';
import mixpanel from 'mixpanel-browser'
// const openLorriApp = () => {
//     console .log('data')
//     window.open('https://company.lorri.in', '_blank', 'noreferrer')
// }

const Header = props => {
  const [showNotifications, setShowNotifications] = useState(false);
  // const [count, setcount] = useState(0)
  const [ishome, setishome] = useState(false)
  const closeBtnRef = useRef(null);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)

  const handleClickCloseMaster = (e) => {
    // Trigger click event on the "close-button" element
    if (closeBtnRef.current) {
      closeBtnRef.current.click();
      setIsNavbarOpen(false);
    }
  };

  useEffect(() => {
    let elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems, {
      edge: "left",
      inDuration: 250,
      outDuration: 250,
      preventScrolling: true,
      onCloseStart: () => {
        setIsNavbarOpen(false)
      },      
    });

    let elems2 = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems2, {});
    var ip = sessionStorage.getItem("ClientIP")
    mixpanel.identify(ip)
    mixpanel.people.set({
      "ClientIP": ip,
      "time": currentRequestTimeStamp()
    });
  }, []);

  // const LoginButton = () => {
  //   Activity([
  //     {
  //       screen: "Home",
  //       module: "Login",
  //       user_activity: "Login Button clicked",
  //       timestamp: currentRequestTimeStamp()
  //     }
  //   ]);
  //   window.open("https://company.lorri.in", "_blank", "noreferrer");
  // };

  const SlidingModal = ({ closeSlidingModal }) => {
    return (
      <div className="Sliding-modal s12 m12" id="Sliding-modal">
        <div className="card " style={{ backgroundColor: "#393185" }}>
          <div className="card-content white-text">
            {window.innerWidth > 600 ? (
              <>
                <center><span className={`${classes['s-heading']}`}>COVID-19 SUPPORT</span></center><br />
                <span className={`${classes['s-title']}`}>Need trucks urgently? Please e-mail your urgent requirements to
                  <a href="mailto:lorri@logisticsnow.in?Subject=Urgent Requirement of Trucks"> lorri@logisticsnow.in</a> and call <a href="tel:+918655724402">+91-9653620207</a></span>
                <hr />
                <div className={`${classes['s-link']}`}>
                  <span className={`${classes['s-link']}`}><br />
                    <a
                      href="/Covid19ImpactReport"
                      className={`${classes['s-link-title']}`}
                      onClick={() => {
                        Activity([
                          {
                            screen: "Covid-Support",
                            module: "Covid-Support",
                            user_activity: "LogisticsNow COVID-19 Industry Impact Report Clicked",
                            timestamp: currentRequestTimeStamp()
                          }
                        ]);
                        ReactGA.event({
                          category: 'LoRRI',
                          action: `LogisticsNow COVID-19 Industry Impact Report Clicked`
                        });
                        mixpanel.track(`User has clicked on LN Covid-19 Industry impact report`, {
                          ApplicationName: `${Urls.mixpanelappname}`,
                          ScreenName: 'home',
                          ModuleName: 'landing_page',
                          ActivityLog: `userClickedIndustryImpactReport`,
                          Data: `None`,
                          Definition: `User has clicked on LN Covid-19 Industry impact report`,
                          ActivityType: 'event_view',
                        });
                      }}
                    ><u> <b> LogisticsNow COVID-19 Industry Impact Report </b> </u></a></span>
                </div>
                <div className={`${classes['s-link']}`}>
                  <span className={`${classes['s-link']}`}><br />
                    <a
                      href="https://maps.mapmyindia.com/corona"
                      className={`${classes['s-link-title']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        Activity([
                          {
                            screen: "Covid-Support",
                            module: "Covid-Support",
                            user_activity: "Corona Information Dashboard Clicked",
                            timestamp: currentRequestTimeStamp()
                          }
                        ]);
                        ReactGA.event({
                          category: 'LoRRI',
                          action: `Corona Information Dashboard Clicked`
                        });
                        mixpanel.track(`User has clicked on Corona Information dashboard`, {
                          ApplicationName: `${Urls.mixpanelappname}`,
                          ScreenName: 'home',
                          ModuleName: 'landing_page',
                          ActivityLog: `userClickedCoronaInformationDashboard`,
                          Data: `None`,
                          Definition: `User has clicked on Corona Information dashboard`,
                          ActivityType: 'event_view',
                        });
                      }}
                    ><u> <b> Corona Information Dashboard</b> </u></a></span>
                </div>
              </>

            ) : (
              <>
                <center><span className={`${classes['s-heading']}`}>COVID-19 SUPPORT</span></center><br />
                <span>Need trucks urgently? Please e-mail your urgent requirements to
                  <a href="mailto:lorri@logisticsnow.in?Subject=Urgent Requirement of Trucks"> lorri@logisticsnow.in</a> and call <a href="tel:+918655724402">+91-8655724402</a></span>
                <hr />

                <div className={`${classes['s-info']}`}>
                  <span className={`${classes['s-link']}`}>
                    <a
                      href="/Covid19ImpactReport"
                      className={`${classes['s-link-title']}`}
                      onClick={() => {
                        Activity([
                          {
                            screen: "Covid-Support",
                            module: "Covid-Support",
                            user_activity: "LogisticsNow COVID-19 Industry Impact Report Clicked",
                            timestamp: currentRequestTimeStamp()
                          }
                        ]);
                        ReactGA.event({
                          category: 'LoRRI',
                          action: `LogisticsNow COVID-19 Industry Impact Report Clicked`
                        });
                        mixpanel.track(`User has clicked on LN Covid-19 Industry impact report`, {
                          ApplicationName: `${Urls.mixpanelappname}`,
                          ScreenName: 'home',
                          ModuleName: 'landing_page',
                          ActivityLog: `userClickedIndustryImpactReport`,
                          Data: `None`,
                          Definition: `User has clicked on LN Covid-19 Industry impact report`,
                          ActivityType: 'event_view',
                        });
                      }}
                    ><u> <b>LogisticsNow COVID-19 Industry Impact Report </b> </u></a></span>
                </div>
                <div className={`${classes['s-info']}`}>
                  <span className={`${classes['s-link']}`}><br />
                    <a
                      href="https://maps.mapmyindia.com/corona"
                      target="_blank" rel="noopener noreferrer"
                      className={`${classes['s-link-title']}`}
                      onClick={() => {
                        Activity([
                          {
                            screen: "Covid-Support",
                            module: "Covid-Support",
                            user_activity: "Corona Information Dashboard Clicked",
                            timestamp: currentRequestTimeStamp()
                          }
                        ]);
                        ReactGA.event({
                          category: 'LoRRI',
                          action: `Corona Information Dashboard Clicked`
                        });
                        mixpanel.track(`User has clicked on Corona Information dashboard`, {
                          ApplicationName: `${Urls.mixpanelappname}`,
                          ScreenName: 'home',
                          ModuleName: 'landing_page',
                          ActivityLog: `userClickedCoronaInformationDashboard`,
                          Data: `None`,
                          Definition: `User has clicked on Corona Information dashboard`,
                          ActivityType: 'event_view',
                        });
                      }}
                    ><u> <b> Corona Information Dashboard</b> </u></a></span>
                </div>
              </>
            )}

          </div>
          <div onClick={closeSlidingModal} className="card-action" style={{ cursor: "pointer", textAlign: "center" }}>
            <center>
              <span className={classes['s-close']}>Close</span>
            </center>
          </div>
        </div>
      </div>
    );
  };

  const closeSlidingModal = () => {
    Activity([
      {
        screen: "Covid-Support",
        module: "Covid-Support",
        user_activity: "Covid-19 Close Clicked",
        timestamp: currentRequestTimeStamp()
      }
    ]);
    ReactGA.event({
      category: 'Home',
      action: `Covid-19 Close Clicked`
    });
    window.innerWidth > 600 ? (
      document.getElementById('Sliding-modal').style.right = '-75%')
      : (document.getElementById('Sliding-modal').style.right = '-400%')
  }

  const openSlidingModal = () => {
    Activity([
      {
        screen: "Covid-Support",
        module: "Covid-Support",
        user_activity: "Covid-19 SideButton Clicked",
        timestamp: currentRequestTimeStamp()
      }
    ]);
    ReactGA.event({
      category: 'Home',
      action: `Covid-19 SideButton Clicked`
    });
    mixpanel.track(`User has clicked on Covid-19 support button`, {
      ApplicationName: `${Urls.mixpanelappname}`,
      ScreenName: 'home',
      ModuleName: 'landing_page',
      ActivityLog: `userClickedCovidSupportButton`,
      Data: `None`,
      Definition: `User has clicked on Covid-19 support button`,
      ActivityType: 'event_view',
    });
    window.innerWidth > 600 ? (
      document.getElementById('Sliding-modal').style.right = '0')
      : (document.getElementById('Sliding-modal').style.right = '0')
  }

  return (
    <div className="navbar-fixed " style={{ zIndex: "1001", position: 'static !important' }}>
      <SlidingModal closeSlidingModal={closeSlidingModal} />
      <div className="top-header show-header-on-lg-screen" >
        <div style={{ display: 'flex', paddingTop: '7px', }}>
          <span><i className="material-icons left mail-icon">email</i></span>
          <a
            className="mail-text"
            href="mailto:lorri@logisticsnow.in"
            onClick={() => {
              Activity([
                {
                  screen: "Header",
                  module: "Header-Mail",
                  user_activity: "Mail clicked",
                  timestamp: currentRequestTimeStamp()
                }
              ]);
              mixpanel.track('User has clicked on mail link button', {
                ApplicationName: `${Urls.mixpanelappname}`,
                ScreenName: 'home',
                ModuleName: 'landing_page',
                ActivityLog: 'userClickedMailLinkButton',
                Data: 'lorri@logisticsnow.in',
                Definition: 'User has clicked on mail link button',
                ActivityType: 'event_view',
              });
            }}
          >
            lorri@logisticsnow.in
          </a>
          {/* <span><i className="material-icons mail-icon">call</i></span> */}
          {/* <i className="material-icons mail-icon">call</i> */}
          <a className="number"><span
            className="mail-text"
            href="tel:9867773508"
            onClick={() => {
              Activity([
                {
                  screen: "Header",
                  module: "Header-Contact",
                  user_activity: "Contact clicked",
                  timestamp: currentRequestTimeStamp()
                }
              ]);
              mixpanel.track(`User has clicked on number`, {
                ApplicationName: `${Urls.mixpanelappname}`,
                ScreenName: 'home',
                ModuleName: 'landing_page',
                ActivityLog: `userClickedNumber`,
                Data: `9867773508`,
                Definition: `User has clicked on number`,
                ActivityType: 'event_view',
              });
            }}
          > +91-9867773508</span></a>
          {/* <span className="number"><a 
            className="mail-text" 
            href="tel:9653620207"
            onClick={()=>{
              Activity([
                {
                  screen: "Header",
                  module: "Header-Contact",
                  user_activity: "Contact clicked",
                  timestamp: currentRequestTimeStamp()
                }
              ]);
              mixpanel.track(`User has clicked on number`, {
                ApplicationName: `${Urls.mixpanelappname}`,
                ScreenName: 'home',   
                ModuleName: 'landing_page',     
                ActivityLog: `userClickedNumber`,     
                Data: `9653620207`,     
                Definition: `User has clicked on number`,     
                ActivityType: 'event_view',
              });
              }}
          > +91-9653620207</a></span> */}
          <div className="schedule-btns-container">
            {/* <button style={{paddingLeft: '3px', marginRight: '2%'}}>
              <LinkedInIcon />
            </button> */}

            {/* {window.innerWidth > 600 ? (
              <a onClick={openSlidingModal} className="btn waves-effect button-covid-desktop"><i className="material-icons right">arrow_drop_up</i>COVID-19 Support</a>
              ) : (
                <a onClick={openSlidingModal} className="btn waves-effect button-covid-mobile"><i className="material-icons right">arrow_drop_up</i>COVID-19 Support</a>
            )} */}
            <a href={window.location === '/' ? "#schedule-form" : '/#schedule-form'}
              onClick={() => {
                Activity([
                  {
                    screen: "Home",
                    module: "Schedule Demo Button",
                    user_activity: "Schedule Demo Button Clicked",
                    timestamp: currentRequestTimeStamp()
                  }
                ]);
                mixpanel.track(`User has clicked on Schedule Demo button`, {
                  ApplicationName: `${Urls.mixpanelappname}`,
                  ScreenName: 'home',
                  ModuleName: 'landing_page',
                  ActivityLog: `userClickedScheduleDemoButton`,
                  Data: `None`,
                  Definition: `User has clicked on Schedule Demo button`,
                  ActivityType: 'event_view',
                });
              }}
              className="schedule-button">
              SCHEDULE MEETING/ DEMO NOW
            </a>
            <span onClick={openSlidingModal} className="schedule-button">
              COVID-19 SUPPORT
            </span>
          </div>
        </div>
      </div>
      <nav className="nav-wrapper white pushpin-demo-nav">
        <div
          className="my-navbar"
          style={{ display: "flex", justifyContent: "space-between", marginTop: '-10px', backgroundColor: isNavbarOpen ? "rgba(0,0,0,0.5)" : "inherit" }}
        >
          <div style={{ display: "contents" }}>
            {window.screen.width < 600 ? (
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://logisticsnow.in"
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "LogisticsNow Logo",
                        user_activity: "LogisticsNow Logo Button Clicked",
                        timestamp: currentRequestTimeStamp()
                      }
                    ]);

                    ReactGA.event({
                      category: 'Header',
                      action: `LogisticsNow Logo Button Clicked`
                    });

                    mixpanel.track(`User has clicked LogisticsNow Logo on header`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: 'home',
                      ModuleName: 'header_element',
                      ActivityLog: `userClickedLogisticsNowLogo`,
                      Data: `None`,
                      Definition: `User has clicked LogisticsNow Logo on header`,
                      ActivityType: 'event_view',
                    });
                  }}
                >
                  <img
                    alt="img"
                    src={LN}
                    className="brand-logo logo-height logo-image left"
                  ></img>
                </a>
              </>
            ) : ('')}

            <div>
              <ul id="nav-mobile" className="left hide-on-med-and-down">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://logisticsnow.in"
                  className="logo-LN"
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "LogisticsNow Logo",
                        user_activity: "LogisticsNow Logo Button Clicked",
                        timestamp: currentRequestTimeStamp()
                      }
                    ]);

                    ReactGA.event({
                      category: 'Header',
                      action: `LogisticsNow Logo Button Clicked`
                    });

                    mixpanel.track(`User has clicked LogisticsNow Logo on header`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: 'home',
                      ModuleName: 'header_element',
                      ActivityLog: `userClickedLogisticsNowLogo`,
                      Data: `None`,
                      Definition: `User has clicked LogisticsNow Logo on header`,
                      ActivityType: 'event_view',
                    });
                  }}
                >
                  <img
                    alt="img"
                    src={LN}
                    style={{ marginTop: '9%' }}
                    height='40'
                  ></img>
                </a>
                {ishome ? <li><a onClick={e => {
                  setishome(false)
                  props.history.push("/")
                }} className="header-list"><b>Home</b></a></li> : null}
              </ul>
            </div>
          </div>
          <div style={{ marginRight: "1%" }}>
            {isNavbarOpen ? (
              // <a
              //   id="close-btn-master"
              //   onClick={(e) => {
              //     e.preventDefault();
              //     handleClickCloseMaster(e)
              //   }}
              //   className="black-text"
              // >
              //   <i className="material-icons close-icon">close</i>
              // </a>
              null
            ) : (
              <a
                href=""
                className="sidenav-trigger black-text"
                data-target="mobile-menu"
                style={{
                  position: window.innerWidth > 600 ? 'relative' : 'absolute!important',
                  right: window.innerWidth > 600 ? '0px' : '8px!important',
                }}
                onClick={() => setIsNavbarOpen(true)}
              >
                <i className="material-icons">menu</i>
              </a>
            )}

            <ul className="right hide-on-med-and-down">
              <div>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-list"
                    href="https://logisticsnow.in/about-us.aspx"
                    onClick={() => {
                      Activity([
                        {
                          screen: "Header",
                          module: "About Us",
                          user_activity: "About Us Button Clicked",
                          timestamp: currentRequestTimeStamp()
                        }
                      ]);

                      ReactGA.event({
                        category: 'Header',
                        action: `About Us Button Clicked`
                      });

                      mixpanel.track(`User has clicked about us on header`, {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: 'home',
                        ModuleName: 'header_element',
                        ActivityLog: `userClickedAboutus`,
                        Data: `None`,
                        Definition: `User has clicked about us on header`,
                        ActivityType: 'event_view',
                      });

                    }}
                  >
                    <b style={{ fontSize: '15px' }}>About Us</b>
                  </a>
                </li>
                <li>
                  <a href="https://company.lorri.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="go"
                    // className="waves-effect btn login-button-color"
                    onClick={() => {
                      Activity([
                        {
                          screen: "Header",
                          module: "Header",
                          user_activity: "Manufacturer Login Button Clicked",
                          timestamp: currentRequestTimeStamp()
                        }
                      ]);

                      ReactGA.event({
                        category: 'Header',
                        action: `Manufacturer Login Button Clicked`
                      });

                      mixpanel.track(`User has clicked on Manufacturer Login button`, {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: 'home',
                        ModuleName: 'header_element',
                        ActivityLog: `userClickedManufacturerLoginButton`,
                        Data: `None`,
                        Definition: `User has clicked on Manufacturer Login button`,
                        ActivityType: 'event_view',
                      });
                    }}

                  >
                    <b>Manufacturer Login</b></a>
                </li>
                <li>

                  <a href="https://transporter.lorri.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    // className="waves-effect btn login-button-color1"
                    id="go"
                    onClick={() => {
                      Activity([
                        {
                          screen: "Header",
                          module: "Header",
                          user_activity: "Transporter/LSP Login Button Clicked",
                          timestamp: currentRequestTimeStamp()
                        }
                      ]);
                      ReactGA.event({
                        category: 'Header',
                        action: `Transporter/LSP Login Button Clicked`
                      });

                      mixpanel.track(`User has clicked on Transporter/LSP Login button`, {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: 'home',
                        ModuleName: 'header_element',
                        ActivityLog: `userClickedTransporterLoginButton`,
                        Data: `None`,
                        Definition: `User has clicked on Transporter/LSP Login button`,
                        ActivityType: 'event_view',
                      });
                    }}
                  >
                    {/* <i className="material-icons left">local_shipping</i> */}
                    {/* <b style={{color: '#393185'}}>Transporter/LSP Login</b></a> */}
                    <b>Transporter/LSP Login</b></a>

                </li>
              </div>
            </ul>
            <ul className="sidenav grey lighten-5" id="mobile-menu" >
              <li>
                <a
                  id="user_button"
                  onClick={e => props.history.push("/")}
                  className="btn sidenav-close"
                  style={{ backgroundColor: "#393185" }}
                >
                  <b>Home</b>
                </a>
              </li>
              <li>
                <a href="https://company.lorri.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="waves-effect btn-small"
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "Header-Mobile",
                        user_activity: "Manufacturer Login Button Clicked",
                        timestamp: currentRequestTimeStamp()
                      }
                    ]);
                    ReactGA.event({
                      category: 'Header',
                      action: `Transporter/LSP Login Button Clicked`
                    });

                    mixpanel.track(`User has clicked on Manufacturer Login button`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: 'home',
                      ModuleName: 'header_element',
                      ActivityLog: `userClickedManufacturerLoginButton`,
                      Data: `None`,
                      Definition: `User has clicked on Manufacturer Login button`,
                      ActivityType: 'event_view',
                    });
                  }}
                ><b>Manufacturer Login</b></a>
              </li>
              <li>
                <a href="https://transporter.lorri.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="waves-effect btn-small"
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "Header-Mobile",
                        user_activity: "Transporter/LSP Login Button Clicked",
                        timestamp: currentRequestTimeStamp()
                      }
                    ]);
                    ReactGA.event({
                      category: 'Header',
                      action: `Transporter/LSP Login Button Clicked`
                    });
                    mixpanel.track(`User has clicked on Transporter/LSP Login button`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: 'home',
                      ModuleName: 'header_element',
                      ActivityLog: `userClickedTransporterLoginButton`,
                      Data: `None`,
                      Definition: `User has clicked on Transporter/LSP Login button`,
                      ActivityType: 'event_view',
                    });
                  }}
                ><b>Transporter/LSP Login</b></a>

              </li>
              <li>
                <a
                  id="user_button"
                  href="https://logisticsnow.in/about-us.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn sidenav-close"
                  style={{ backgroundColor: "#393185" }}
                  onClick={() => {
                    Activity([
                      {
                        screen: "Header",
                        module: "Header-Mobile",
                        user_activity: "About Us Login Button Clicked",
                        timestamp: currentRequestTimeStamp()
                      }
                    ]);
                    ReactGA.event({
                      category: 'Header',
                      action: `About Us Login Button Clicked`
                    });

                    mixpanel.track(`User has clicked about us on header`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: 'home',
                      ModuleName: 'header_element',
                      ActivityLog: `userClickedAboutus`,
                      Data: `None`,
                      Definition: `User has clicked about us on header`,
                      ActivityType: 'event_view',
                    });
                  }}
                >
                  <b style={{ fontSize: '15px' }}>About Us</b>
                </a>
              </li>
              <li>
                <a
                  id="user_button"
                  onClick={e => props.history.push("/Covid19ImpactReport")}
                  className="btn sidenav-close"
                  style={{ backgroundColor: "#393185" }}
                >
                  <b>Covid19-Impact Report</b>
                </a>
              </li>
              <li>
                <a
                  id="user_button"
                  onClick={() => setShowNotifications(!showNotifications)}
                  className="btn sidenav-close"
                  style={{ backgroundColor: "#393185" }}
                >
                  <b>What is LoRRI?</b>
                </a>
              </li>
              <li>
                <a
                  id="close-button"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  // }}
                  className="sidenav-close float-right"
                  ref={closeBtnRef}
                >
                  <i className="material-icons close-icon white-text" style={{fontSize: "5px"}}>close</i>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
      {showNotifications ? (
        <ClickOutSideContext.Provider
          value={{
            onClickOutSide: () => {
              setShowNotifications(false);
            }
          }}
        >
          <ClickOutSide className="MyClass">
            <div style={{ margin: "2%" }}>
              <div>
                <span style={{ color: "white", fontSize: "15px" }}>
                  <span style={{ fontSize: "25px" }}>LoRRI</span> is the trusted
                  logistics intelligence platform which enables companies and
                  transporters to drive savings, find their best fit partners
                  while leveraging actionable insights to enable reach and
                  reduce risk. LoRRI is created by{" "}
                  <a
                    href="https://www.logisticsnow.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    <u>LogisticsNow</u>
                  </a>{" "}
                  a pioneer in applying collective intelligence and
                  crowdsourcing to solve for logistics efficiency at scale.
                </span>
              </div>
            </div>
          </ClickOutSide>
        </ClickOutSideContext.Provider>
      ) : null}
    </div>
  );
};
export default withRouter(Header);
