import React from "react";
// import PropTypes from "prop-types";
import './Footer.css';
import { Activity, currentRequestTimeStamp } from "./../Constants";
import mixpanel from 'mixpanel-browser'
import {Urls} from '../../Urls'
const Footer = props => {
  return (
    <footer className="page-footer" style={{paddingTop: '0px', color: '#fff', backgroundColor: 'black', opacity: '0.9' }}>
      <div className="container">
        <div className="row height-shorten">
            <center className="">
              <br />
              <ul>
                <li style={{marginLeft: "5%"}}>
                <a 
                  className="white-text" 
                  href="mailto:lorri@logisticsnow.in"
                  onClick={()=>{
                    Activity([
                      {
                        screen: "Footer",
                        module: "Footer-Mail",
                        user_activity: "Mail clicked",
                        timestamp: currentRequestTimeStamp()
                      }
                    ]);
                    mixpanel.track(`User has clicked on mail link button`, {
                      ApplicationName: `${Urls.mixpanelappname}`,
                      ScreenName: 'home',   
                      ModuleName: 'footer',     
                      ActivityLog: `userClickedMailLinkButton`,     
                      Data: `lorri@logisticsnow.in`,     
                      Definition: `User has clicked on mail link button`,     
                      ActivityType: 'event_view',
                    });
                    }}
                  > lorri@logisticsnow.in</a>
                  <a 
                    className="white-text grey-text text-lighten-3 s-link" 
                    href="tel:9867773508"
                    onClick={()=>{
                      Activity([
                        {
                          screen: "Footer",
                          module: "Footer-Contact",
                          user_activity: "Contact clicked",
                          timestamp: currentRequestTimeStamp()
                        }
                      ]);
                      mixpanel.track(`User has clicked on number`, {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: 'home',   
                        ModuleName: 'footer',     
                        ActivityLog: `userClickedNumber`,     
                        Data: `9867773508`,     
                        Definition: `User has clicked on number`,     
                        ActivityType: 'event_view',
                      });
                      }}
                  > +91-9867773508 </a>
                  {/* <a 
                    className="white-text grey-text text-lighten-3 s-link" 
                    href="tel:9867773508"
                    onClick={()=>{
                      Activity([
                        {
                          screen: "Footer",
                          module: "Footer-Contact",
                          user_activity: "Contact clicked",
                          timestamp: currentRequestTimeStamp()
                        }
                      ]);
                      mixpanel.track(`User has clicked on number`, {
                        ApplicationName: `${Urls.mixpanelappname}`,
                        ScreenName: 'home',   
                        ModuleName: 'landing_page',     
                        ActivityLog: `userClickedNumber`,     
                        Data: `9653620207`,     
                        Definition: `User has clicked on number`,     
                        ActivityType: 'event_view',
                      });
                      }}
                  >+91-9653620207</a> */}
                </li>  
              </ul>
          </center>

          <center className="">
            <br />
            <ul id="useful-link">
              <li style={{marginLeft: "5%"}}>
                <a 
                className="grey-text text-lighten-3 s-link" 
                href="https://lntermsandconditions.blob.core.windows.net/tnc/benchmark_tnc.html" 
                target="_blank" rel="noopener noreferrer"
                id="termsofuse"
                onClick={()=>{
                  Activity([
                    {
                      screen: "Footer",
                      module: "Footer-TnC",
                      user_activity: "Terms of Use clicked",
                      timestamp: currentRequestTimeStamp()
                    }
                  ]);
                  mixpanel.track(`User has clicked on Terms of Use`, {
                    ApplicationName: `${Urls.mixpanelappname}`,
                    ScreenName: 'home',   
                    ModuleName: 'footer',     
                    ActivityLog: `userClickedTermsofUse`,     
                    Data: `None`,     
                    Definition: `User has clicked on Terms of Use`,     
                    ActivityType: 'event_view',
                  });
                  }}
                >
                  Terms of Use
                </a>
              </li>
              <li style={{marginLeft: "5%"}}>
                <a 
                className="grey-text text-lighten-3 s-link" 
                href="https://lntermsandconditions.blob.core.windows.net/tnc/privacypolicy.html" 
                target="_blank" 
                id="privacy_policy"
                rel="noopener noreferrer"
                onClick={()=>{
                  Activity([
                    {
                      screen: "Footer",
                      module: "Footer - Privacy Policy",
                      user_activity: "Privacy Policy clicked",
                      timestamp: currentRequestTimeStamp()
                    }
                  ]);
                  mixpanel.track(`User has clicked on Privacy Policy`, {
                    ApplicationName: `${Urls.mixpanelappname}`,
                    ScreenName: 'home',   
                    ModuleName: 'footer',     
                    ActivityLog: `userClickedPrivacyPolicy`,     
                    Data: `None`,     
                    Definition: `User has clicked on Privacy Policy`,     
                    ActivityType: 'event_view',
                  });
                  }}
                >
                  Privacy Policy
                </a>
              </li>
              <li style={{marginLeft: "5%"}}>
                <a 
                className="grey-text text-lighten-3 s-link" 
                href="https://www.logisticsnow.in/about-us.aspx" 
                target="_blank" 
                id="about_us"
                rel="noopener noreferrer"
                onClick={()=>{
                  Activity([
                    {
                      screen: "Footer",
                      module: "Footer - About Us",
                      user_activity: "About Us clicked",
                      timestamp: currentRequestTimeStamp()
                    }
                  ]);
                  mixpanel.track(`User has clicked about us`, {
                    ApplicationName: `${Urls.mixpanelappname}`,
                    ScreenName: 'home',   
                    ModuleName: 'footer',     
                    ActivityLog: `userClickedAboutus`,     
                    Data: `None`,     
                    Definition: `User has clicked about us`,     
                    ActivityType: 'event_view',
                  });
                  }}
                >
                  About Us
                </a>
              </li>
            </ul>
          </center>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <center><a href="https://www.logisticsnow.in" target="_blank" rel="noopener noreferrer" style={{color:'white'}}>© LogisticsNow 2019</a></center>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
