import React from 'react';
import './Loader.css';

const Loader = ({ show = false, message = '' }) =>
  show ? (
    <>
      <div className="loader-wrapper">
        <span className="message">{message}</span>
        {/* <i className="loading-icon" /> */}
        <div className={`preloader-wrapper big ${show?'active':''}`}>
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div><div className="gap-patch">
              <div className="circle"></div>
            </div><div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="loader-modal-container" />
    </>
  ) : null;

export default Loader;
