import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

// import logo from "./logo.svg";
import "./App.css";
import Applayout from "./Components/Applayout/Applayout";
// import M from "materialize-css";
import ReactGA from "react-ga";
import axios from "axios";
import mixpanel from "mixpanel-browser";
const gaId = process.env.REACT_APP_GOOGLE_ID;
// const gaId = 'UA-141365221-4'
ReactGA.initialize(gaId);

const mixpanelId = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(mixpanelId);

const App = () => {
  const clarity_ID = process.env.REACT_APP_CLARITY_CODE;

  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", clarity_ID);

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((res) => sessionStorage.setItem("ClientIP", res.data.ip));
    // window.fcWidget.open();
    // window.fcWidget.show();
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        theme="light"
      />
      <Applayout />
    </div>
  );
};

export default App;
