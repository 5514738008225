// [documentation] - docs/Modal.md

import mixpanel from "mixpanel-browser";
import React from "react";
import ReactGA from "react-ga";
import { Urls } from "../../Urls";
import { Activity, currentRequestTimeStamp } from "./../Constants";
import styles from "./modal.module.css";

const Modal = ({ show, onClose, children, isClosable }) => {
  if (!show) return null;

  return (
    <div
      className={styles["modal-overlay"]}
      onClick={isClosable ? onClose : null}
    >
      <div
        className={styles["modal-content"]}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export const NoAccessModal = ({ show, handleClose, isClosable }) => {
  return (
    <Modal show={show} onClose={handleClose}>
      <div style={{padding: '10px'}}>
        <div className={`${styles["modal-header"]}`}>
          <h4 className={`${styles["modal-title"]}`}>
            You have reached the maximum search limit!
          </h4>
          {isClosable && (
            <button
              type="button"
              className={styles["modal-close"]}
              onClick={handleClose}
            >
              <span>&times;</span>
            </button>
          )}
        </div>
        <div className={`${styles["modal-body"]}`}>
          <p>
            Please Signup/Login on LoRRI to continue or{" "}
            <a
              href="https://company.lorri.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>
          </p>
        </div>
        <div className={`${styles["modal-footer"]}`}>
          <button
            className="btn btn-primary"
            onClick={() => {
              Activity([
                {
                  screen: "Signup Popup",
                  module: "Schedule Demo Button",
                  user_activity: "Schedule Demo Button Clicked",
                  timestamp: currentRequestTimeStamp(),
                },
              ]);
              ReactGA.event({
                category: "Signup Popup",
                action: `Schedule Demo Button Clicked`,
              });
              mixpanel.track(`User has clicked on Schedule Demo button`, {
                ApplicationName: `${Urls.mixpanelappname}`,
                ScreenName: "Signup Popup",
                ModuleName: "signup_popup",
                ActivityLog: `userClickedScheduleDemoButton`,
                Data: `None`,
                Definition: `User has clicked on Schedule Demo button`,
                ActivityType: "event_view",
              });

              handleClose();

              window.location.href =
                window.location.pathname === "/"
                  ? "#schedule-form"
                  : "/#schedule-form";
            }}
          >
            <b>SCHEDULE MEETING/ DEMO NOW</b>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const CollectEmailModal = ({
  show,
  handleClose,
  emailinputHandler,
  numberinputHandler,
  submitHandler,
  isClosable,
}) => {
  return (
    <Modal show={show} onClose={handleClose}>
      <div className={styles["modal-header"]}>
        <h4 className={styles["modal-title"]}>
          Please provide your email id and phone number to proceed!
        </h4>
        {isClosable && (
          <button className={styles["modal-close"]} onClick={handleClose}>
            &times;
          </button>
        )}
      </div>
      <div className={styles["modal-body"]}>
        <form onSubmit={submitHandler}>
          <input
            type="email"
            className={styles["modal-input"]}
            onChange={emailinputHandler}
            placeholder="Enter your email address"
            required
          />
          <input
            type="number"
            className={styles["modal-input"]}
            onChange={numberinputHandler}
            placeholder="Enter your phone number"
          />
        </form>
      </div>
      <div className={styles["modal-footer"]}>
        <button
          className="btn btn-primary"
          onClick={submitHandler}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};
