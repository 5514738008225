const BaseUrl = process.env.REACT_APP_API_BaseUrl;
// const BaseUrl = "http://apilorri.azurewebsites.net"
const UserBaseUrl = process.env.REACT_APP_API_USER_BaseUrl;
const WebBaseUrl = process.env.REACT_APP_TINASPA_BASEURL;
const MixPanelApplicationname = process.env.REACT_APP_APPLICATION_NAME;
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const Urls = {
  New_BaseUrls: `${WebBaseUrl}`,
  Autocomplete: `${UserBaseUrl}/api/autocomplete`,
  Autocomplete1: {
    Get: {
      getlist: `${UserBaseUrl}/autocomplete`,
    },
  },
  GetLocations: `${BaseUrl}/api/screen_zero`,
  log: `${BaseUrl}/api/log`,
  logURL: `${BaseUrl}/api/log`,
  // EmailApi: `https://tpmpemail.azurewebsites.net/api/trigger_email`
  EmailApi: `https://tpmpemail.azurewebsites.net/api/trigger_emailv2`,
  streamingData: `${BaseUrl}/api/streaming-data`,
  mixpanelappname: `${MixPanelApplicationname}`,
  mapboxAccessToken: mapboxAccessToken,
};
